import React from "react";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button/Button";
import Text from "../../../../components/Text/Text";
import { Flex, Grid, ScrollContent } from "./styled";
import dayjs from "dayjs";
import { HistoryCheckEntities } from "../../../../entities/OrderCheckEntity";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  detailData: HistoryCheckEntities | undefined;
  setDetailData: React.Dispatch<React.SetStateAction<HistoryCheckEntities | undefined>>;
}
const HistoryModal: React.FC<Props> = ({ open, setOpen, detailData, setDetailData }) => {
  const onCloseModal = () => {
    setDetailData(undefined);
    setOpen(false);
  };
  return (
    <Modal visible={open} onCancel={onCloseModal} width={600}>
      <Text level={2}>บันทึกการตรวจสอบ</Text>
      <Grid columns='1fr 1fr' paddingTop={14}>
        <Flex gap={20}>
          <Text level={6} color='grayishBlue' fontFamily='Sarabun'>
            วันที่อัปเดต :
          </Text>
          <Text level={6} fontFamily='Sarabun'>
            {dayjs(detailData?.createdAt).format("DD/MM/YYYY HH:mm น.")}
          </Text>
        </Flex>
        <Flex gap={20}>
          <Text level={6} color='grayishBlue' fontFamily='Sarabun'>
            ผู้ตรวจสอบ :
          </Text>
          <Text level={6} fontFamily='Sarabun'>
            {detailData?.user}
          </Text>
        </Flex>
      </Grid>
      <div style={{ margin: "12px 0" }}>
        <Text level={6} color='grayishBlue' fontFamily='Sarabun'>
          ข้อความ :
        </Text>
      </div>
      <ScrollContent>
        <Text fontFamily='Sarabun'>{detailData?.detail}</Text>
      </ScrollContent>
      <Button title='ปิด' onClick={onCloseModal} typeButton='primary-light' />
    </Modal>
  );
};
export default HistoryModal;

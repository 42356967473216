import { Modal } from "antd";
import React from "react";
import Text from "../Text/Text";
import Button from "../Button/Button";

interface Props {
  visible: boolean;
  onSubmit: () => void;
  icon?: string;
  iconW?: number | string;
  iconH?: number | string;
  header?: string;
  label?: string;
  confirmText?: string;
  width?: number | string;
  bodyStyle?: React.CSSProperties;
}

function ModalError({
  visible,
  onSubmit,
  icon,
  iconW,
  iconH,
  header,
  label,
  width,
  bodyStyle,
  confirmText,
  ...props
}: Props) {
  return (
    <Modal
      open={visible}
      closable={false}
      bodyStyle={{ ...bodyStyle }}
      centered
      width={width || 400}
      footer={null}
      {...props}
    >
      <div style={{ padding: "4px 4px", textAlign: "center", margin: 18 }}>
        <img src={icon} style={{ width: iconW, height: iconH, marginBottom: 18 }} />
        <br />
        <Text fontFamily='IBM Plex Sans Thai' fontSize={24} fontWeight={700}>
          {header}
        </Text>
        <br />
        <Text fontFamily='Sarabun' fontSize={16} fontWeight={400} color='Text3'>
          {label}
        </Text>
      </div>
      <Button title={confirmText} onClick={onSubmit} />
    </Modal>
  );
}

export default ModalError;

import React from "react";
import { Col, Row } from "antd";
import Text from "../../Text/Text";
import icon from "../../../resource/icon";
import color from "../../../resource/color";
import { numberFormatter } from "../../../utility/Formatter";
import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${color.background1};
  padding: 8px;
  border-radius: 0 0 10px 10px;
  margin: 16px 0;
`;

type OrderContentProps = {
  data: any;
  typeColumn: string;
  orderNav: number;
};

export function OrderContent({ data, typeColumn, orderNav = 0 }: OrderContentProps) {
  const renderContent = () => (
    <>
      <Row>
        <Col span={16} style={{ display: "flex", alignItems: "center" }}>
          <img src={icon.orderList} style={{ width: 22, height: 22, marginRight: 8 }} />
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            Order จากระบบ Navision
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            {numberFormatter(orderNav, 2)} ตัน
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={16} style={{ display: "flex", alignItems: "center" }}>
          <img src={icon.productStock} style={{ width: 22, height: 22, marginRight: 8 }} />
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            Stock ในร้าน
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text
            fontFamily='Sarabun'
            fontSize={14}
            fontWeight={400}
            color={data ? "Text1" : "warning"}
          >
            {data !== null ? `${numberFormatter(data, 2)} ตัน` : "ไม่ได้ตั้งค่า"}
          </Text>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {typeColumn !== "dealer" && <Container>{renderContent()}</Container>}
      {typeColumn === "dealer" && renderContent()}
      {!data && (
        <div
          style={{
            backgroundColor: color.lightWarning,
            padding: 8,
            marginTop: 14,
            borderRadius: 4,
          }}
        >
          <Row>
            <Col span={2}>
              <InfoCircleOutlined style={{ color: color.warning }} />
            </Col>
            <Col span={22}>
              <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
                เพื่อให้การตรวจจับแม่นยำ โปรดตั้งค่า จำนวนสต๊อกร้านในเมนู “กำหนดสต๊อกร้านค้า”
              </Text>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

import React, { useEffect, useState, type JSX } from "react";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { FlexCol } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Row, Spin, Tabs } from "antd";
import DetailTab from "./Tabs/DetailTab";
import HistoryTab from "./Tabs/HistoryTab";
import {
  PointDetailEntity,
  PointDetailEntity_INIT,
  PointHistoryEntity,
} from "../../../entities/PointApproveEntity";
import { getPointDetail, getPointHistory } from "../../../datasource/OrderPointDatasource";
import { MAP_STATUS_COLOR, MAP_STATUS_WORD } from "../../../definitions/approvePoint";
import moment from "moment";
import { dateFormatter } from "../../../utility/Formatter";
import color from "../../../resource/color";
import icon from "../../../resource/icon";

function ApprovePointDetail() {
  const navigate = useNavigate();
  const orderPointId = window.location.pathname.split("/")[3];
  const [historyData, setHistoryData] = useState<PointHistoryEntity[]>([]);
  const [detail, setDetail] = useState<PointDetailEntity>(PointDetailEntity_INIT);
  const [loading, setLoading] = useState(false);

  const getDetailPointApprove = async () => {
    setLoading(true);
    try {
      const params = {
        orderPointId: orderPointId,
        campaignProgramId: "",
      };
      const res = await getPointDetail(params);
      setDetail(res);
    } catch (error) {
      console.error("Error fetching point history", error);
    } finally {
      setLoading(false);
    }
  };
  const getHistoryPointApprove = async () => {
    setLoading(true);
    try {
      const res = await getPointHistory(orderPointId);
      setHistoryData(res || []);
    } catch (error) {
      console.error("Error fetching point history", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetailPointApprove();
    getHistoryPointApprove();
  }, [orderPointId]);

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={detail.orderPointCode}
        showBack
        onBack={() => navigate(-1)}
        extra={
          <FlexCol align='end'>
            {detail.isChecked ? (
              <Row>
                <Text
                  level={3}
                  fontWeight={700}
                  style={{
                    color: MAP_STATUS_COLOR[detail.status],
                    fontWeight: 700,
                    marginRight: 12,
                  }}
                >
                  {MAP_STATUS_WORD[detail.status] + "แต้ม"}
                </Text>
                {detail.isChecked ? (
                  <div
                    style={{
                      borderLeft: `1px solid ${color.Text3}`,
                      height: "35px",
                    }}
                  >
                    <div>
                      <Row
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        <Text
                          level={3}
                          fontWeight={700}
                          style={{
                            color: color.Text1,
                          }}
                        >
                          ตรวจสอบแล้ว
                        </Text>
                        <img src={icon.checked} style={{ width: 24, height: 24 }} />
                      </Row>
                      <Text
                        fontWeight={400}
                        fontSize={12}
                        fontFamily='Sarabun'
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        โดย {detail.historyOrderPoint.slice(-1)[0].updateBy}
                      </Text>
                      <Text
                        fontWeight={400}
                        fontSize={12}
                        fontFamily='Sarabun'
                        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                      >
                        {"วันที่อัปเดท" +
                          " " +
                          dateFormatter(detail.historyOrderPoint.slice(-1)[0].updateAt, true)}
                      </Text>
                    </div>
                  </div>
                ) : (
                  <Text fontFamily='Sarabun' fontSize={12}>
                    {detail.historyOrderPoint.slice(-1)[0].updateAt
                      ? "วันที่อัปเดท" +
                        " " +
                        dateFormatter(detail.historyOrderPoint.slice(-1)[0].updateAt, true)
                      : undefined}
                  </Text>
                )}
              </Row>
            ) : (
              <>
                <Text
                  level={3}
                  fontWeight={700}
                  style={{
                    color: MAP_STATUS_COLOR[detail.status],
                    fontWeight: 700,
                    marginRight: 12,
                  }}
                >
                  {MAP_STATUS_WORD[detail.status] + "แต้ม"}
                </Text>
                {detail.status === "CANCEL" && (
                  <Text
                    fontWeight={400}
                    fontSize={12}
                    fontFamily='Sarabun'
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    อัปเดทโดย {detail.historyOrderPoint.slice(-1)[0].updateBy}
                  </Text>
                )}
                <Text fontFamily='Sarabun' fontSize={12}>
                  {detail.historyOrderPoint.slice(-1)[0].updateAt
                    ? "วันที่อัปเดท" +
                      " " +
                      dateFormatter(detail.historyOrderPoint.slice(-1)[0].updateAt, true)
                    : undefined}
                </Text>
              </>
            )}
          </FlexCol>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการอนุมัติแต้ม",
                path: -1,
              },
              { text: "รายละเอียดอนุมัติแต้ม", path: window.location.pathname },
            ]}
          />
        }
      />
    );
  };

  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      key: "DETAIL",
      label: (
        <Text fontSize={14} fontWeight={400}>
          รายละเอียด
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <DetailTab data={detail} newFetch={() => getDetailPointApprove()} />
      ),
    },
    {
      key: "HISTORY",
      label: (
        <Text fontSize={14} fontWeight={400}>
          ประวัติทำรายการ
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <HistoryTab data={historyData} />
      ),
    },
  ];
  const handleTabChange = (key: string) => {
    if (key === "HISTORY") {
      getHistoryPointApprove();
    }
  };
  return (
    <>
      <CardContainer>
        <PageTitle />
        <div style={{ padding: 22 }}>
          <Tabs items={tabsData} onChange={handleTabChange} />
        </div>
      </CardContainer>
    </>
  );
}

export default ApprovePointDetail;

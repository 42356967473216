import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";
import { PostHistoryCheckEntities } from "../entities/OrderCheckEntity";

const url = `${BASE_URL}/crm`;

const getOrderCheckDatasource = async (
  page: number,
  take: number,
  company: string,
  campaignId: string,
  search?: string,
  zone?: string,
  isAbnormal?: boolean,
  warningLevel?: string[],
  sortBy?: string,
  sortDirection?: string,
) => {
  const params = {
    page: page,
    take: take,
    company: company,
    campaignId: campaignId,
    search: search,
    zone: zone,
    isAbnormal: isAbnormal,
    warningLevel: warningLevel,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };
  return await httpClient
    .get(`${url}/ordercheck`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getOrderCheckById = async (id: string) => {
  return await httpClient
    .get(`${url}/ordercheck/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getSubDealerCheck = async (
  page: number,
  take: number,
  orderCheckId: string,
  search?: string,
  zone?: string,
  min?: number,
  max?: number,
  sortBy?: string,
  sortDirection?: string,
) => {
  const params = {
    page: page,
    take: take,
    orderCheckId: orderCheckId,
    search: search,
    zone: zone,
    min: min,
    max: max,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };
  return await httpClient
    .get(`${url}/subdealercheck`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const getProductCheck = async (params: {
  page: number;
  take: number;
  orderCheckId: string;
  search?: string;
  sortBy?: string;
  sortDirection?: "ASC" | "DESC";
}) => {
  return await httpClient
    .get(`${url}/productcheck`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const postHistoryCheck = async (payload: PostHistoryCheckEntities) => {
  return await httpClient.post(`${url}/historycheck`, payload);
};

const getHistoryCheck = async (params: { page: number; take: number; orderCheckId: string }) => {
  return await httpClient
    .get(`${url}/historycheck`, { params })
    .then((res) => res.data)
    .catch((err) => err);
};

export {
  getOrderCheckDatasource,
  getOrderCheckById,
  getSubDealerCheck,
  getProductCheck,
  postHistoryCheck,
  getHistoryCheck,
};

import React, { JSX, useEffect } from "react";
import { Modal as AntdModal } from "antd";
import Text from "../Text/Text";
import icon from "../../resource/icon";
import Button from "../Button/Button";
import { downLoadTemplate } from "../../datasource/StockDatasource";
import { StockDetailEntities } from "../../entities/CampaignStockEntity";
interface Props {
  title?: string;
  visible?: boolean;
  onSubmit?: () => void;
  bodyStyle?: React.CSSProperties;
  children?: React.ReactNode;
  width?: number | string;
  maskClosable?: boolean;
  detail: StockDetailEntities;
}
function ModalSendTemplate({
  visible,
  bodyStyle,
  onSubmit,
  children,
  width,
  detail,
  ...props
}: Props): JSX.Element {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  useEffect(() => {
    if (visible) {
      downloadFileTemplate();
    }
  }, [visible]);

  const downloadFileTemplate = async () => {
    try {
      const payload = {
        campaignId: detail.campaignId,
        campaignName: detail.campaignName,
        campaignCode: detail.campaignCode,
        email: userProfile?.email,
        firstName: userProfile?.firstname,
        lastName: userProfile?.lastname,
      };
      const res = await downLoadTemplate(payload);
      if (!res) {
        throw new Error("No data received for the download template");
      }
    } catch (error) {
      console.error("Failed to download template:", error);
    }
  };
  return (
    <AntdModal
      open={visible}
      closable={false}
      bodyStyle={{ ...bodyStyle }}
      centered
      width={width || 400}
      footer={null}
      {...props}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <img src={icon.send_mail} style={{ width: 142, height: 100 }} />
        <br />
        <Text fontFamily='IBM Plex Sans Thai' fontSize={20} fontWeight={700}>
          จัดส่งไฟล์เทมเพลตไปอีเมลของคุณ โปรดตรวจสอบกล่องอีเมล
        </Text>
        <br />
        <Text fontFamily='Sarabun' fontSize={16} fontWeight={400} color='grayishBlue'>
          อาจใช้เวลาดำเนินการประมาณ 1-2 นาที หากท่านไม่ได้รับอีเมล กรุณาตรวจสอบ ในโฟลเดอร์ Junk หรือ
          Spam
        </Text>
        <br />
        <div style={{ marginTop: 10 }}>
          <Button title='ตกลง' typeButton='primary' onClick={onSubmit} />
        </div>
      </div>
    </AntdModal>
  );
}

export default ModalSendTemplate;

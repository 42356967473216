import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import Text, { colorTypes } from "../../../../components/Text/Text";
import icon from "../../../../resource/icon";
import { Content, Flex, Grid, LevelColor, Line } from "./styled";
import color from "../../../../resource/color";
import TotalOrder from "../../../../components/Popover/OrderTrackingList/TotalOrder";
import ProductTable from "./table/ProductTable";
import Button from "../../../../components/Button/Button";
import SubDealerTable from "./table/SubDealerTable";
import React, { useState } from "react";
import dayjs from "dayjs";
import AddRecordModal from "./AddRecordModal";
import { Link } from "react-router-dom";
import ModalPartnerList from "../../../../components/Modal/ModalPartnerList";
import { OrderCheckEntities } from "../../../../entities/OrderCheckEntity";
import DetailStatusDanger from "../../../../components/Popover/OrderTrackingList/DetailStatusDanger";
import { Button as AntdButton } from "antd";
interface Alert {
  key: string;
  title: string;
  icon: string | null;
  bgColor: string;
  color: colorTypes;
}
interface Props {
  data: OrderCheckEntities;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

const DetailTab: React.FC<Props> = ({ data, setRefresh, id }) => {
  const [selectedTable, setSelectedTable] = useState<"product" | "subDealer">("product");
  const [recordModal, setRecordModal] = useState<boolean>(false);
  const [subDealerPage, setSubDealerPage] = useState<number>(1);
  const [productPage, setProductPage] = useState<number>(1);
  const [partnerListModal, setPartnerListModal] = useState<boolean>(false);

  const alertLevel: Alert[] = [
    {
      key: "NORMAL",
      title: "ไม่พบความผิดปกติ",
      icon: null,
      bgColor: "transparent",
      color: "Text1",
    },
    {
      key: "LOOKING",
      title: "จับตามอง",
      icon: icon.dangerYellow,
      bgColor: color.warning1,
      color: "darkGold",
    },
    {
      key: "PRENORMAL",
      title: "เริ่มผิดปกติ",
      icon: icon.dangerOrange,
      bgColor: color.critical,
      color: "chocolateBrown",
    },
    {
      key: "ABNORMAL",
      title: "เสี่ยงผิดปกติ",
      icon: icon.dangerRed,
      bgColor: color.emergency,
      color: "darkRed",
    },
  ];

  const buttonTab = [
    {
      key: "product",
      title: "ยอดสินค้า",
      activeIcon: icon.crmProductActive,
      icon: icon.crmProductInactive,
      onClick: () => {
        setSelectedTable("product");
        setProductPage(1);
      },
    },
    {
      key: "subDealer",
      title: "ร้าน Sub-Dealer",
      activeIcon: icon.crmStoreActive,
      icon: icon.crmStoreInactive,
      onClick: () => {
        setSelectedTable("subDealer");
        setSubDealerPage(1);
      },
    },
  ];

  const AlertLevel = () => {
    let item = alertLevel[0];
    if (data) {
      item = alertLevel.find((item) => item.key === data.warningLevel) as Alert;
    }
    return item.key === "NORMAL" ? (
      <Text level={6} fontWeight={700} fontFamily='Sarabun' color={item.color}>
        {item.title}
      </Text>
    ) : (
      <DetailStatusDanger title='รายละเอียดสถานะแจ้งเตือน' data={data}>
        <LevelColor bgColor={item.bgColor} className='pointer'>
          {item.icon ? <img src={item?.icon} width={18} height={18} alt={item.key} /> : <></>}
          <Text level={6} fontWeight={700} fontFamily='Sarabun' color={item.color}>
            {item.title}
          </Text>
        </LevelColor>
      </DetailStatusDanger>
    );
  };

  const OrderCheckTime = () => (
    <Flex gap={16} flexDirection='column' paddingBottom={40} paddingTop={16}>
      <Text level={4} fontWeight={600}>
        ช่วงเวลาการตรวจจับ
      </Text>
      <Flex gap={5} flexDirection='column'>
        <Content padding='8px 12px' radius={4} style={{ width: 300 }}>
          <Text level={6}>{data?.campaignName}</Text>
        </Content>
        <Text fontFamily='Sarabun' fontSize={12} color='Text3'>
          {`ระยะเวลาแคมเปญ ${dayjs(data?.startDate).format("DD/MM/YYYY")} - ${dayjs(
            data?.endDate,
          ).format("DD/MM/YYYY")}`}
        </Text>
      </Flex>
    </Flex>
  );

  const CheckDetail = () => (
    <Flex gap={16} flexDirection='column'>
      <Text level={4} fontWeight={600}>
        ข้อมูลการตรวจจับ
      </Text>
      <Content>
        <Grid columns='1fr 1fr' gap={12}>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ระดับแจ้งเตือน :
          </Text>
          <AlertLevel />
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            วันที่อัปเดต :
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.updatedAt ? dayjs(data?.updatedAt).format("DD/MM/YYYY HH:mm") : "-"}
          </Text>
        </Grid>
        <Line />
        <Grid columns='1fr 1fr' gap={12}>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ชื่อกลุ่มร้านที่ตรวจจับ :
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.examineGroupName}
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            % การตรวจจับยอดสั่งซื้อ :
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.percent} %
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ยอดสั่งซื้อ Dealer
          </Text>
          <Flex gap={6} alignItems='center'>
            <Text level={5} fontFamily='Sarabun'>
              {`${data?.dealerOrderSum?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ตัน`}
            </Text>
            <TotalOrder
              title='ยอดสั่งซื้อ Dealer'
              data={data?.dealerOrder}
              orderNav={data?.dealerOrderNav || 0}
            >
              <InfoCircleOutlined
                style={{ fontSize: "16px", color: "#464E5F" }}
                className='pointer'
              />
            </TotalOrder>
          </Flex>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ยอดสั่งซื้อ Sub-Dealer
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {`${data?.subDealerOrder?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ตัน`}
          </Text>
        </Grid>
      </Content>
    </Flex>
  );

  const StoreDetail = () => (
    <Flex gap={16} flexDirection='column'>
      <Text level={4} fontWeight={600}>
        ข้อมูลร้านค้า
      </Text>
      <Content>
        <Grid columns='1fr 1fr' gap={12}>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            รหัสร้านค้า
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.dealerCode}
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ชื่อร้านค้า
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.dealerName}
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            ประเภทคู่ค้า
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            Dealer
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            เขต
          </Text>
          <Text level={5} fontFamily='Sarabun'>
            {data?.zone}
          </Text>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            จำนวนคู่ค้า Sub-Dealer
          </Text>
          <Flex gap={6} alignItems='center'>
            <Text level={5} fontFamily='Sarabun'>
              {`${data?.countSubDealer} ร้าน`}
            </Text>
            <InfoCircleFilled
              style={{ fontSize: "16px", color: color.Text1 }}
              onClick={() => setPartnerListModal(true)}
              className='pointer'
            />
          </Flex>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun'>
            รายละเอียดเพิ่มเติม
          </Text>
          <Link
            to={`/ShopManagementPage/detailCorporateShop/${data?.dealerId}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Flex gap={6} alignItems='center' className='pointer'>
              <Text level={5} fontFamily='Sarabun' color='primary'>
                ดูข้อมูล
              </Text>
              <img src={icon.ArrowUpRight} alt='preview' width={16} height={16} />
            </Flex>
          </Link>
        </Grid>
      </Content>
    </Flex>
  );

  const SavingHistory = () => (
    <>
      <br />
      <Text level={4} fontWeight={600}>
        บันทึกการตรวจสอบล่าสุด
      </Text>
      <Content bgColor='white' style={{ margin: "16px 0" }}>
        <Grid columns='0.5fr 1fr'>
          <Flex>
            <Text level={5} color='grayishBlue' fontFamily='Sarabun' style={{ minWidth: 120 }}>
              วันที่อัปเดต :
            </Text>

            <Text
              level={5}
              color={data?.history?.createdAt !== "-" ? "Text1" : "Text3"}
              fontFamily='Sarabun'
            >
              {data?.history?.createdAt !== "-"
                ? dayjs(data?.history?.createdAt).format("DD/MM/YYYY HH:mm น.")
                : "-"}
            </Text>
          </Flex>
          <Flex>
            <Text level={5} color='grayishBlue' fontFamily='Sarabun' style={{ minWidth: 120 }}>
              ผู้ตรวจสอบ :
            </Text>
            <Text
              level={5}
              color={data?.history?.user !== "-" ? "Text1" : "Text3"}
              fontFamily='Sarabun'
            >
              {data?.history?.user || "-"}
            </Text>
          </Flex>
        </Grid>
        <Flex marginTop={16}>
          <Text level={5} color='grayishBlue' fontFamily='Sarabun' style={{ minWidth: 120 }}>
            บันทึกข้อความ :
          </Text>
          <Text
            level={5}
            color={data?.history?.detail !== "ยังไม่ได้บันทึก" ? "Text1" : "Text3"}
            fontFamily='Sarabun'
            style={{ whiteSpace: "pre-wrap" }}
          >
            {data?.history?.detail}
          </Text>
        </Flex>
      </Content>
      <Button
        title='เพิ่มบันทึก'
        iconPlacement='left'
        icon={<img src={icon.plus} width={20} height={20} alt='plus-icon' />}
        onClick={() => {
          setRecordModal(true);
        }}
      />
    </>
  );
  return (
    <>
      <OrderCheckTime />
      <Grid columnGap={20} columns='1fr 1fr' marginBottom={40}>
        <CheckDetail />
        <StoreDetail />
      </Grid>
      <Text level={4} fontWeight={600}>
        รายละเอียดเชิงลึก
      </Text>
      <Flex gap={8} margin='16px 0'>
        {buttonTab.map((item) => {
          const isActive = selectedTable === item.key;
          return (
            <AntdButton
              key={item.key}
              type={isActive ? "primary" : "default"}
              icon={
                <img
                  src={isActive ? item.activeIcon : item.icon}
                  width={16}
                  height={16}
                  alt='product-icon'
                  style={{ marginTop: "-2px" }}
                />
              }
              style={{
                width: "fit-content",
                padding: "8px 16px",
                height: 40,
                fontSize: 16,
                borderColor: color.secondary,
                color: isActive ? "white" : color.secondary,
                background: isActive ? color.secondary : "white",
              }}
              onClick={item.onClick}
            >
              {item.title}
            </AntdButton>
          );
        })}
      </Flex>
      {selectedTable === "product" && (
        <ProductTable
          page={productPage}
          setPage={setProductPage}
          selectedTable={selectedTable}
          id={id}
        />
      )}
      {selectedTable === "subDealer" && (
        <SubDealerTable
          page={subDealerPage}
          setPage={setSubDealerPage}
          selectedTable={selectedTable}
          id={id}
        />
      )}
      <SavingHistory />
      <AddRecordModal
        open={recordModal}
        setOpen={setRecordModal}
        id={data?.orderCheckId}
        setRefresh={setRefresh}
      />
      <ModalPartnerList
        open={partnerListModal}
        onCancel={() => setPartnerListModal(false)}
        id={data?.orderCheckId}
      />
    </>
  );
};
export default DetailTab;

import { color } from "../resource";
import icon from "../resource/icon";

export const MAP_DANGER_LEVEL = [
  { key: 1, value: "NORMAL", label: "ไม่มีความผิดปกติ" },
  { key: 2, value: "LOOKING", label: "จับตามอง" },
  { key: 3, value: "PRENORMAL", label: "เริ่มผิดปกติ" },
  { key: 4, value: "ABNORMAL", label: "ผิดปกติ" },
];
export const STATUS_DANGER_WORD: any = {
  LOOKING: "จับตามอง",
  PRENORMAL: "เริ่มผิดปกติ",
  ABNORMAL: "ผิดปกติ",
};
export const STATUS_DANGER_COLOR: any = {
  LOOKING: color["warning1"],
  PRENORMAL: color["critical"],
  ABNORMAL: color["emergency"],
};
export const DANGER_WORD_COLOR: any = {
  LOOKING: color["darkGold"],
  PRENORMAL: color["chocolateBrown"],
  ABNORMAL: color["darkRed"],
};

export const STATUS_DANGER_ICON: any = {
  LOOKING: icon.dangerYellow,
  PRENORMAL: icon.dangerOrange,
  ABNORMAL: icon.dangerRed,
};

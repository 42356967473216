import React from "react";
import styled from "styled-components";
import { color } from "../../resource";

const BaseText = styled.div<{ bgColor: string }>`
  font-family: "IBM Plex Sans Thai";
  color: ${color.white};
  font-size: 14px;
  font-weight: 700;
  background-color: ${(props) => props.bgColor};
  border-radius: 3px;
  padding: 0px 10px;
  width: max-content;
  height: 26px;
  display: flex;
  align-items: center;
`;

interface Props {
  label: string;
  bgColor: string;
  icon?: string;
  textStyle?: React.CSSProperties;
}

function StatusBadge({ label, bgColor, icon, textStyle }: Props) {
  return (
    <BaseText bgColor={bgColor}>
      {icon && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 6,
          }}
        >
          <img src={icon} style={{ width: 16, height: 16 }} alt='icon' />
        </div>
      )}
      <span style={textStyle}>{label}</span>
    </BaseText>
  );
}

export default StatusBadge;

import React, { useState } from "react";
import Modal from "./Modal";
import Button from "../Button/Button";
import Text from "../Text/Text";
import { Col, Row } from "antd";

interface Props {
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

function ModalConfirmUploadTemplate({ visible, onCancel, onSubmit }: Props) {
  return (
    <Modal visible={visible} onCancel={onCancel} maskClosable={false} width={600}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "0px 0px 22px 0px",
        }}
      >
        <Text align='left' fontWeight={700} fontSize={24}>
          ยืนยันอัปโหลดไฟล์นำเข้า
        </Text>
        <Text align='left' fontWeight={400} fontSize={16} color='error' fontFamily='Sarabun'>
          การอัปโหลดไฟล์ครั้งนี้เป็นการอัปโหลดทับข้อมูลเดิม อาจส่งผลต่อ
          การแสดงผลข้อมูลการตรวจจับยอดสั่งซื้อในระบบ โปรดตรวจสอบรายละเอียด ให้แน่ใจก่อนยืนยัน
        </Text>
      </div>

      <Row justify='end'>
        <Col span={6} style={{ paddingRight: 5 }}>
          <Button
            typeButton='primary-light'
            title='ยกเลิก'
            style={{ width: "100%" }}
            onClick={onCancel}
          />
        </Col>
        <Col span={6} style={{ paddingLeft: 5 }}>
          <Button
            typeButton={"primary"}
            title='ยืนยัน'
            style={{ width: "100%" }}
            onClick={onSubmit}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalConfirmUploadTemplate;

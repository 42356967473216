import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import Text from "../Text/Text";
import Button from "../Button/Button";

interface Props {
  visible: boolean;
  onSubmit: () => void;
  iconW?: number | string;
  iconH?: number | string;
  header?: string;
  label?: string;
  confirmText?: string;
  width?: number | string;
  bodyStyle?: React.CSSProperties;
  retryAction?: () => void;
}

function NetworkStatusModal({
  visible,
  onSubmit,
  iconW,
  iconH,
  header,
  label,
  width,
  bodyStyle,
  confirmText = "เข้าใจแล้ว",
  retryAction,
  ...props
}: Props) {
  return (
    <Modal
      open={visible}
      closable={false}
      bodyStyle={{ ...bodyStyle }}
      centered
      width={width || 400}
      footer={[
        <Button key='ok' onClick={onSubmit} title={confirmText}>
          {confirmText}
        </Button>,
      ]}
      {...props}
    >
      <div style={{ padding: "4px 4px", textAlign: "center", margin: 18 }}>
        <img
          src={require("../../resource/media/icons/network_err.png")}
          style={{ width: iconW, height: iconH, marginBottom: 18 }}
        />
        <br />
        <Text fontFamily='IBM Plex Sans Thai' fontSize={24} fontWeight={700}>
          {header}
        </Text>
        <br />
        <Text fontFamily='Sarabun' fontSize={16} fontWeight={400} color='Text3'>
          {label}
        </Text>
      </div>
    </Modal>
  );
}

export default NetworkStatusModal;

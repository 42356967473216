import React, { useRef, useState } from "react";
import { Button, Modal, notification, Row, Col, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Text from "../Text/Text";
import icon from "../../resource/icon";
import ModalConfirmUploadTemplate from "../Modal/ModalConfirmUploadTemplate";
import { upLoadTemplate } from "../../datasource/StockDatasource";
import ModalError from "../Modal/ModalError";
import NetworkStatusModal from "../Modal/NetworkStatusModal";

interface UploadCSVButtonProps {
  title: string;
  campaignId: string;
  statusImport?: boolean;
  stockId?: string;
}

const UploadCSVButton = ({ title, statusImport, campaignId, stockId }: UploadCSVButtonProps) => {
  const [api, contextHolder] = notification.useNotification();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalState, setModalState] = useState({
    isModalVisible: false,
    visible: false,
    modalError: false,
    modalOffline: false,
  });
  const [progressValue, setProgressValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);

  const handleError = (error: string) => {
    console.error(error);
    setModalState((prevState) => ({ ...prevState, modalError: true }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    uploadFile(file);
    e.target.value = "";
  };

  const uploadFile = async (file: File) => {
    if (!navigator.onLine) {
      setModalState((prevState) => ({ ...prevState, modalOffline: true }));
      return;
    }

    if (statusImport) {
      setSelectedFile(file);
      setModalState((prevState) => ({ ...prevState, visible: true }));
      return;
    }

    try {
      const payload = createPayload(file);
      const res = await upLoadTemplate(payload);
      if (!res) throw new Error("No data received for the upload template");

      startUploadProcess(formatFileSize(file.size));
    } catch (error) {
      handleError("Failed to upload template: " + error);
    }
  };

  const createPayload = (file: File) => ({
    stockId,
    campaignId,
    company: userProfile?.company,
    updatedBy: `${userProfile?.firstname} ${userProfile?.lastname}`,
    file,
    role: userProfile?.role,
  });

  const startUploadProcess = (fileSize: string) => {
    let progress = 0;
    setProgressValue(progress);

    const updateNotification = () => {
      const isSpinnerPhase = progress < 70;
      api.open({
        message: (
          <Row style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Col
              span={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              {isSpinnerPhase ? <Spin size='large' /> : <Spin percent={progress} size='large' />}
            </Col>
            <Col span={20}>
              <Row>
                <Text fontSize={16} fontWeight={700}>
                  กำลังอัปโหลดไฟล์ของคุณ รออีกนิด!
                </Text>
                <br />
                <Text fontSize={14} fontWeight={400} color='grayishBlue'>
                  ไฟล์ขนาด {fileSize}
                </Text>
              </Row>
            </Col>
          </Row>
        ),
        placement: "bottomRight",
        duration: null,
        key: "uploadNotification",
      });
    };

    const interval = setInterval(() => {
      progress += 10;
      setProgressValue(progress);
      updateNotification();

      if (progress >= 100) {
        clearInterval(interval);
        api.destroy("uploadNotification");
        setModalState((prevState) => ({ ...prevState, isModalVisible: true }));
        setTimeout(() => {
          setModalState((prevState) => ({ ...prevState, isModalVisible: false }));
          window.location.reload();
        }, 800);
      }
    }, 500);
  };

  const handleModalSubmit = async () => {
    setModalState((prevState) => ({ ...prevState, visible: false }));
    try {
      const payload = createPayload(selectedFile!);
      const res = await upLoadTemplate(payload);
      if (!res) throw new Error("No data received for the upload template");

      startUploadProcess(formatFileSize(selectedFile!.size));
    } catch (error) {
      handleError("Failed to upload template: " + error);
    }
  };

  const formatFileSize = (size: number): string => {
    if (size < 1024) return `${size} Bytes`;
    if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  };

  return (
    <div>
      {contextHolder}
      <Button
        style={{ height: 42 }}
        disabled={progressValue > 0 && progressValue !== 100}
        type='primary'
        onClick={() => fileInputRef.current?.click()}
        icon={<UploadOutlined />}
      >
        {title}
      </Button>
      <input
        type='file'
        accept='.xlsx, .xls, .csv'
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Modal
        width={180}
        visible={modalState.isModalVisible}
        footer={null}
        closable={false}
        centered
        destroyOnClose
      >
        <div style={{ textAlign: "center" }}>
          <img src={icon.checked} style={{ width: 60, height: 60, marginBottom: 14 }} />
          <br />
          <Text fontSize={20} fontWeight={700}>
            อัปโหลดเสร็จเรียบร้อย
          </Text>
        </div>
      </Modal>
      <ModalConfirmUploadTemplate
        visible={modalState.visible}
        onCancel={() => setModalState((prevState) => ({ ...prevState, visible: false }))}
        onSubmit={handleModalSubmit}
      />
      <ModalError
        visible={modalState.modalError}
        onSubmit={() => {
          setModalState((prevState) => ({ ...prevState, modalError: false }));
          window.location.reload();
        }}
        icon={icon.network_err}
        iconW={86}
        iconH={120}
        confirmText='เข้าใจแล้ว'
        header='การอัปโหลดไฟล์ไม่สำเร็จ'
        label='เนื่องจากมีการแก้ไขข้อมูลในส่วนที่ไม่อนุญาตหรือเลือกไฟล์เทมเพลตไม่ถูกต้อง กรุณาตรวจสอบและแก้ไขไฟล์ให้ตรงตามข้อกำหนดก่อนลองใหม่อีกครั้ง'
      />
      <NetworkStatusModal
        visible={modalState.modalOffline}
        onSubmit={() => setModalState((prevState) => ({ ...prevState, modalOffline: false }))}
        iconW={87}
        iconH={120}
        header='เกิดข้อผิดพลาดในการอัปโหลด'
        label='กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ต และลองใหม่อีกครั้ง หากยังไม่สามารถแก้ไขได้ โปรดติดต่อเจ้าหน้าที่'
      />
    </div>
  );
};

export default UploadCSVButton;

import { AxiosResponse } from "axios";
import { BASE_URL, NAV_URL, httpClient } from "../config/develop-config";
import { DealerType, HistoryCampaignType } from "../definitions/campaign";

const baseUrl = `${BASE_URL}/crm/examine-group`;
// const baseUrl = "http://localhost:3008";

const getThreshold = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/examine-group`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getThresholdById = async (examineGroupId: string) => {
  return await httpClient
    .get(`${baseUrl}/examine-group-by-id/${examineGroupId}`, { params: { examineGroupId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getDealerInGroup = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/examine-group-dealer`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createThreshold = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/create-examine-group`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateThreshold = async (data: object) => {
  return await httpClient
    .patch(`${baseUrl}/update-examine-group`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const deleteThreshold = async (examineGroupId: string) => {
  return await httpClient
    .delete(`${baseUrl}/delete-examine-group/${examineGroupId}`, { params: { examineGroupId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const deleteDealerInGroup = async (data: object) => {
  return await httpClient
    .patch(`${baseUrl}/delete-examine-group-dealer`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  getThreshold,
  getThresholdById,
  getDealerInGroup,
  createThreshold,
  updateThreshold,
  deleteThreshold,
  deleteDealerInGroup,
};

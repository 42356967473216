import styled from "styled-components";
import color from "../../resource/color";

export const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

export const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;
import React, { useEffect, useState } from "react";
import { Table, Row, Col, Image, Tag, Modal, message, Divider } from "antd";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { numberFormatter } from "../../../utility/Formatter";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import { STATUS_COLOR_MAPPING } from "../../../definitions/product";
import color from "../../../resource/color";
import image from "../../../resource/image";
import { CardContainer, OuterCardContainer } from "../../../components/Card/CardContainer";
import { checkPermission } from "../../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { roleAtom } from "../../../store/RoleAtom";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";
import TableContainer from "../../../components/Table/TableContainer";
import { GreyTableContainer } from "../../../components/Table/GreyTable";
import dayjs from "dayjs";
import { ActionBtn } from "../../../components/Button/TableActionButton";
import TablePagination from "../../../components/Table/TablePagination";
import { deleteThreshold, getThreshold } from "../../../datasource/ThresholdDatasource";

const ThresholdIndex: React.FC = () => {
  const pageSize = 8;
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { firstname, lastname, company } = userProfile;

  const roleData = useRecoilValue(roleAtom);
  const isView = checkPermission(["freebieList", "view"], roleData);
  const isEdit = checkPermission(["freebieList", "edit"], roleData);

  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<{
    data: any[];
    count: number;
  }>({
    data: [],
    count: 0,
  });

  useEffect(() => {
    fetchData();
  }, [keyword, page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      await getThreshold({
        company,
        search: keyword,
        // sortBy, sortDirection
        page,
        take: pageSize,
      })
        .then((res) => {
          // console.log(res);
          setDataState(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "ชื่อกลุ่ม",
      dataIndex: "examineGroupName",
      key: "examineGroupName",
      width: "30%",
      render: (value: any, row: any, index?: number) => {
        return <Text level={5}>{value || "-"}</Text>;
      },
    },
    {
      title: "% ตรวจจับ",
      dataIndex: "percent",
      key: "percent",
      render: (value: any, row: any, index?: number) => {
        return <Text level={5}>{value || "-"}%</Text>;
      },
    },
    {
      title: "จำนวน Dealer",
      dataIndex: "countDealer",
      key: "countDealer",
      render: (value: any, row: any, index?: number) => {
        return (
          <FlexCol>
            <Text level={5}>{value || "-"}</Text>
            <Text level={6} color='Text3'>
              ร้าน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "อัพเดทโดย",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (value: any, row: any, index?: number) => {
        return (
          <FlexCol>
            <Text level={5}>{dayjs(row?.updateAt).format("DD/MM/YYYY HH:mm")}</Text>
            <Text level={6} color='Text3'>
              {value}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "examineGroupId",
      key: "examineGroupId",
      render: (value: any, row: any, index?: number) => {
        return (
          <FlexRow>
            <ActionBtn
              icon={<img src={icon.iconEdit} style={{ width: 16, height: 16 }} />}
              onClick={() => navigate("/DetectOrderPage/threshold/edit/" + value)}
            />
            &nbsp;&nbsp;
            <ActionBtn
              icon={<img src={icon.delete} style={{ width: 16, height: 16 }} />}
              onClick={() =>
                Modal.confirm({
                  title: "ยืนยันการลบ",
                  onOk: async () => {
                    await deleteThreshold(value)
                      .then((res) => {
                        if (res.success) {
                          message.success("ลบกลุ่มสำเร็จ");
                          location.reload();
                        } else {
                          message.error("เกิดข้อผิดพลาด");
                        }
                      })
                      .catch(() => message.error("เกิดข้อผิดพลาด"));
                  },
                })
              }
            />
          </FlexRow>
        );
      },
    },
  ];

  return (
    <>
      <OuterCardContainer>
        <CardContainer cutBottom>
          <PageTitle
            title='ตั้งค่าเกณฑ์ตรวจจับ'
            extra={
              <Row justify={"space-between"} gutter={16}>
                <Col>
                  <div>
                    <Input
                      placeholder='ชื่อกลุ่มร้านค้า'
                      suffix={<SearchOutlined style={{ color: "grey" }} />}
                      defaultValue={keyword}
                      onPressEnter={(e) => {
                        const value = (e.target as HTMLTextAreaElement).value;
                        setKeyword(value);
                        setPage(1);
                      }}
                      onChange={(e) => {
                        const value = (e.target as HTMLTextAreaElement).value;
                        if (!value) {
                          setKeyword("");
                          setPage(1);
                        }
                      }}
                    />
                  </div>
                </Col>
                <Divider type='vertical' style={{ height: "40px", margin: 0 }} />
                <Col>
                  <Button
                    title='เพิ่มกลุ่มตรวจจับ'
                    icon={<PlusOutlined />}
                    onClick={() => navigate("/DetectOrderPage/threshold/create")}
                  />
                </Col>
              </Row>
            }
          />
        </CardContainer>
        <Divider />
        <CardContainer cutTop>
          <FlexRow align='center' style={{ marginBottom: 14 }}>
            <Text>
              &nbsp;
              <img
                src={icon.store}
                style={{ width: "18px", height: "18px", margin: "0 12px 6px 0" }}
              />
              ร้าน Dealer ทั้งหมด&nbsp;:&nbsp;
              <span style={{ fontWeight: 700 }}>{dataState.count || "0"}&nbsp;ร้าน</span>
            </Text>
            <Divider type='vertical' style={{ height: "40px", margin: "0px 16px" }} />
            <ExclamationCircleOutlined
              style={{ color: color.primary, fontSize: 17, margin: "0 4px 4px 0" }}
            />
            <Text>
              หากร้านค้าไม่ได้กำหนด % เข้ากลุ่มตรวจจับ ระบบจะตั้งค่าเริ่มต้นเป็น 50% โดยอัตโนมัติ
            </Text>
          </FlexRow>
          {/* <GreyTableContainer> */}
          <TablePagination
            columns={columns}
            scroll={{ x: "max-content" }}
            data={dataState?.data?.map((d: object, i) => ({ ...d, key: i }))}
            pagination={{
              position: ["bottomCenter"],
              pageSize,
              current: page,
              total: dataState?.count,
              onChange: (p) => {
                setPage(p);
              },
              showSizeChanger: false,
            }}
            isLoading={loading}
          />
          {/* </GreyTableContainer> */}
        </CardContainer>
      </OuterCardContainer>
    </>
  );
};

export default ThresholdIndex;

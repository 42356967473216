export const inputNumberValidator = (rule: any, value: any, callback: any) => {
  if (!parseInt(value) || parseInt(value) < 1) {
    throw new Error();
  }
  return Promise.resolve();
};

export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export function validateOnlyNumber(t: string): string {
  const inputValue = t;
  const convertedNumber = inputValue.replace(/^[^\d].[^\d]/g, "");
  return convertedNumber;
}

export function validateOnlyNumWDecimal(t: string): string {
  const inputValue = t;
  const convertedNumber = inputValue.replace(/^0+|[^0-9.]/g, "");
  return convertedNumber;
}

export function onInputNumberKeyPress(event: any, options?: { onlyInt: boolean }): void {
  if (options?.onlyInt && event.key === ".") {
    event.preventDefault();
  } else if (event.key === "." && event.target?.value.split(".").length > 1) {
    event.preventDefault();
  }
  if (
    !/[0-9]/.test(event.key) &&
    ![".", "Backspace", "ArrowLeft", "ArrowRight", "Meta", "Tab"].includes(event.key) &&
    !event.ctrlKey
  ) {
    event.preventDefault();
  }
  const origString = event.target.value;
  const indexPosition = event.target.selectionStart;
  const stringToAdd = event.key;
  const newString =
    origString.slice(0, indexPosition) + stringToAdd + origString.slice(indexPosition);
  if (
    event.key === "0" &&
    indexPosition !== origString.length &&
    `${parseFloat(newString)}` !== newString
  ) {
    event.preventDefault();
  }
}

import styled from "styled-components";
import color from "../../../../resource/color";

interface ContentProps {
  bgColor?: string;
  radius?: number;
  padding?: string;
}
interface CssProps {
  padding?: string;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  margin?: string;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
}
interface FlexProps extends CssProps {
  gap?: number;
  alignItems?: "flex-start" | "flex-end" | "start" | "center" | "end" | "unset" | "normal";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  flexDirection?: "column" | "row";
}
interface GridProps extends CssProps {
  gap?: number;
  columnGap?: number;
  rowGap?: number;
  alignItems?: "flex-start" | "flex-end" | "start" | "center" | "end" | "unset" | "normal";
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly";
  columns?: string;
  rows?: string;
  gridRow?: string;
}

interface LevelProps {
  bgColor?: string;
}

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ gap }) => `${gap}px`};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
  padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => `${paddingRight}px`};
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

const Grid = styled.div<GridProps>`
  display: grid;
  gap: ${({ gap }) => `${gap}px`};
  column-gap: ${({ columnGap }) => `${columnGap}px`};
  row-gap: ${({ rowGap }) => `${rowGap}px`};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  grid-template-columns: ${({ columns }) => columns};
  grid-template-rows: ${({ rows }) => rows};
  grid-row: ${({ gridRow }) => gridRow};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
  padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => `${paddingRight}px`};
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}px`};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
`;

const Content = styled.div<ContentProps>`
  background-color: ${({ bgColor }) => (bgColor ? color[bgColor] : color.background1)};
  border: 1px solid ${color.background2};
  border-radius: ${({ radius }) => radius || 16}px;
  padding: ${({ padding }) => padding || "28px"};
  height: 100%;
`;

const ScrollContent = styled.div`
  background-color: ${color.disabledArea};
  border: 1px solid ${color.background2};
  border-radius: 4px;
  min-height: 220px;
  max-height: 220px;
  padding: 10px 16px;
  overflow-y: scroll;
  margin-bottom: 16px;
`;

const Line = styled.div`
  border-bottom: 1px solid ${color.background2};
  margin: 16px 0;
`;

const LevelColor = styled.div<LevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: ${({ bgColor }) => (bgColor ? "4px" : "0px")};
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  padding: ${({ bgColor }) => (bgColor ? "4px 10px" : "0px")};
  width: fit-content;
`;
export { Flex, Grid, Content, ScrollContent, Line, LevelColor };

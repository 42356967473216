import React, { useState, useEffect } from "react";
import { AutoComplete, Input } from "antd";
import { AutoCompleteProps } from "antd/es/auto-complete";
import styled from "styled-components";
import { debounce } from "lodash";

interface Option {
  label: React.JSX.Element | string;
  value: string;
  code: string;
  data: any;
}

interface InputPropsStyled {
  height?: string;
  width?: string;
}

interface CustomAutoCompleteProps extends AutoCompleteProps {
  placeholder: string;
  data: Option[];
  // value: string;
  setValue: (value: string) => void;
  onCampaignSelect: (data: any) => void;
  onPopupScroll: React.UIEventHandler<HTMLDivElement> | undefined;
}

const InputStyled = styled(Input)<InputPropsStyled>`
  padding: 8px !important;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "40px"};
  font-family: Sarabun, sans-serif !important;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  placeholder,
  data,
  value,
  setValue,
  onCampaignSelect,
  onPopupScroll,
  ...props
}) => {
  const [options, setOptions] = useState<Option[]>(data);

  const handleSearch = debounce((input: string) => {
    const filteredOptions = data.filter((option) => {
      return (
        option.value.toLowerCase().includes(input.toLowerCase()) ||
        option.code.toLowerCase().includes(input.toLowerCase())
      );
    });
    setOptions(filteredOptions);
  }, 500);

  const handleSelect = (selectedValue: string) => {
    const selectedOption = data.find((option) => option.value === selectedValue);
    if (selectedOption) {
      setValue(selectedOption.value);
      onCampaignSelect(selectedOption);
    }
  };

  useEffect(() => {
    setOptions(data);
  }, [data]);

  return (
    <AutoComplete
      onPopupScroll={onPopupScroll}
      options={options}
      onSelect={handleSelect}
      onSearch={handleSearch}
      defaultValue={value}
      style={{ width: "100%" }}
      {...props}
    >
      <InputStyled
        size='large'
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </AutoComplete>
  );
};

export default CustomAutoComplete;

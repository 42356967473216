import React, { useMemo, useState } from "react";
import TablePagination from "../../../../../components/Table/TablePagination";
import Text from "../../../../../components/Text/Text";
import icon from "../../../../../resource/icon";
import { Flex } from "../styled";
import { SearchOutlined } from "@ant-design/icons";
import color from "../../../../../resource/color";
import Input from "../../../../../components/Input/Input";
import { zoneDatasource } from "../../../../../datasource/ZoneDatasource";
import { useQuery } from "react-query";
import { getSubDealerCheck } from "../../../../../datasource/orderCheckDatasource";
import useSorting from "../../../../../hook/useSorting";
import { SubDealerCheckEntities } from "../../../../../entities/OrderCheckEntity";
import SortFieldCustom from "../../../../../components/SortFieldTable/SortFieldCustom";
import Select from "../../../../../components/Select/Select";

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedTable: string;
  id: string;
}

const SubDealerTable: React.FC<Props> = ({ page, setPage, selectedTable, id }) => {
  const company = JSON.parse(localStorage?.getItem("company") || "");
  const take = 10;
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [searchZone, setSearchZone] = useState<string | undefined>(undefined);
  const [searchMin, setSearchMin] = useState<number | undefined>(undefined);
  const [searchMax, setSearchMax] = useState<number | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();

  const isMoreThanMax = useMemo(() => {
    if (searchMin && searchMax && searchMin > searchMax) {
      return true;
    }
    return false;
  }, [searchMin, searchMax]);

  // const isLessThanMin = useMemo(() => {
  //   if (searchMin && searchMax && searchMax < searchMin) {
  //     return true;
  //   }
  //   return false;
  // }, [searchMin, searchMax]);

  const subDealer = useQuery(
    [
      "subDealerTable",
      selectedTable,
      id,
      searchText,
      searchZone,
      searchMin,
      searchMax,
      sortField,
      sortDirection,
      page,
    ],
    async () => {
      if (selectedTable === "subDealer" && !!id) {
        return await getSubDealerCheck(
          page,
          take,
          id,
          searchText,
          searchZone,
          searchMin,
          searchMax,
          sortField,
          sortDirection,
        );
      }
    },
    { enabled: selectedTable === "subDealer" && !!id, refetchOnWindowFocus: false },
  );

  const zone = useQuery(
    ["zone", selectedTable, company?.companyCode],
    async () => {
      if (selectedTable === "subDealer" && !!company?.companyCode) {
        return await zoneDatasource.getAllZoneByCompany(company?.companyCode);
      }
    },
    {
      enabled: selectedTable === "subDealer" && !!company?.companyCode,
      refetchOnWindowFocus: false,
    },
  );

  const columns = [
    {
      key: "subDealerName",
      dataIndex: "subDealerName",
      title: (
        <Text level={5} fontWeight={700}>
          ชื่อร้านค้า
        </Text>
      ),
      render: (text, record: SubDealerCheckEntities) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            {`${record?.subDealerCode} • Sub-Dealer`}
          </Text>
        </Flex>
      ),
    },
    {
      key: "zone",
      dataIndex: "zone",
      width: 150,
      title: (
        <Text level={5} fontWeight={700}>
          เขต
        </Text>
      ),
      render: (text) => (
        <div style={{ marginBottom: 23 }}>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
        </div>
      ),
    },
    {
      key: "countOrder",
      dataIndex: "countOrder",
      width: 250,
      title: (
        <SortFieldCustom
          title='จำนวนคำสั่งซื้อ'
          sortDirection={sortField === "countOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("countOrder")}
        />
      ),
      render: (text) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            รายการ
          </Text>
        </Flex>
      ),
    },
    {
      key: "amountOrder",
      dataIndex: "amountOrder",
      width: 250,
      title: (
        <SortFieldCustom
          title='ยอดสั่งซื้อ'
          sortDirection={sortField === "amountOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("amountOrder")}
        />
      ),
      render: (text) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text?.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            ตัน
          </Text>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex justifyContent='space-between' paddingBottom={isMoreThanMax ? 36 : 16} alignItems='end'>
        <Flex gap={8}>
          <Input
            placeholder='รหัส / ชื่อร้านค้า'
            suffix={<SearchOutlined style={{ color: color.Text3 }} />}
            defaultValue={searchText}
            onPressEnter={(e) => {
              const value = (e.target as HTMLTextAreaElement).value;
              setSearchText(value);
              setPage(1);
            }}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement).value;
              if (!value) {
                setSearchText(undefined);
                setPage(1);
              }
            }}
            style={{
              width: "300px",
              fontFamily: "Sarabun",
            }}
          />
          <Select
            value={searchZone}
            allowClear
            placeholder='เขตทั้งหมด'
            data={
              zone?.data?.map((z) => ({
                label: z.zoneName,
                key: z.zoneId,
                value: z.zoneName,
              })) || []
            }
            style={{ width: "200px" }}
            onChange={(e) => {
              setSearchZone(e);
              setPage(1);
            }}
          />
          <Flex gap={4} alignItems='center' style={{ width: 300, position: "relative" }}>
            <Input
              type='number'
              placeholder='ปริมาณต่ำสุด'
              defaultValue={searchMin}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                if (value) {
                  setSearchMin(Number(value));
                } else {
                  setSearchMin(undefined);
                }
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchMin(undefined);
                  setPage(1);
                }
              }}
              style={{
                width: "100%",
                fontFamily: "Sarabun",
              }}
            />
            {isMoreThanMax ? (
              <Text color='error' level={5} style={{ position: "absolute", bottom: -28 }}>
                ปริมาณต่ำสุดต้องไม่มากกว่าปริมาณสูงสุด
                {/* {isMoreThanMax
            ? "ปริมาณต่ำสุดต้องไม่มากกว่าปริมาณสูงสุด"
            : "ปริมาณสูงสุดต้องไม่น้อยกว่าปริมาณต่ำสุด"} */}
              </Text>
            ) : (
              <></>
            )}
            <Text>-</Text>
            <Input
              type='number'
              placeholder='ปริมาณสูงสุด'
              defaultValue={searchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                if (value) {
                  setSearchMax(Number(value));
                } else {
                  setSearchMax(undefined);
                }
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchMax(undefined);
                  setPage(1);
                }
              }}
              style={{
                width: "100%",
                fontFamily: "Sarabun",
              }}
            />
          </Flex>
        </Flex>
        <Text level={6}>{`ทั้งหมด ${subDealer?.data?.count || 0} รายการ`}</Text>
      </Flex>
      <TablePagination
        // isLoading={subDealer?.isLoading}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 32,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 24,
                minHeight: 350,
              }}
            >
              <img
                src={icon.emptyHistoryPoint}
                style={{
                  width: 80,
                  height: 90,
                }}
                alt='empty_table'
              />
              <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                ไม่มีรายการร้านค้า
              </Text>
            </div>
          ),
        }}
        columns={columns}
        data={subDealer?.data?.data || []}
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: take,
          total: subDealer?.data?.count,
          onChange: (p) => {
            setPage(p);
          },
        }}
        isQuickJumper
        onPageChange={(pageNum) => setPage(pageNum)}
      />
    </>
  );
};

export default SubDealerTable;

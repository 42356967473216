import React, { useEffect, useState } from "react";
import Text from "../../../../components/Text/Text";
import { Empty, Table } from "antd";
import styled from "styled-components";
import color from "../../../../resource/color";
import icon from "../../../../resource/icon";
import { StockHistoryEntities } from "../../../../entities/CampaignStockEntity";
import moment from "moment";

export const CustomTableWrapper = styled.div`
  .ant-table-thead > tr > th {
    background-color: ${color.secondary};
    color: ${color.white};
    font-family: "IBM Plex Sans Thai";
    font-weight: 600;
    font-size: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr > td {
    font-family: "Sarabun";
    font-size: 16px;
    font-weight: 400;
    color: ${color.Text1};
    padding: 16px;
    text-align: start;
  }

  .ant-table-tbody > tr:nth-child(odd) {
    background-color: ${color.white};
  }

  .ant-table {
    border-radius: 8px;
    overflow: hidden;
    border: 0.3px solid ${color.placeholder};
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;

const DescText = styled.div`
  font-family: "Sarabun";
  font-size: 14px;
  color: ${color.placeholder};
  font-weight: 400;
`;

interface Props {
  data: StockHistoryEntities[];
}
function HistoryTab({ data }: Props) {

  const columns = [
    {
      title: "วันเวลาอัปเดท",
      dataIndex: "updateAt",
      key: "updateAt",
      render: (value: string) => <BaseText>{moment(value).format('DD/MM/YYYY, HH:mm น.')}</BaseText>,
    },
    {
      title: "กิจกรรม",
      dataIndex: "activity",
      key: "activity",
      render: (value: string) => <BaseText>{value}</BaseText>,
    },
    {
      title: "ผู้ใช้งาน",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (value: string, row: any) => (
        <>
          <BaseText>{value || "-"}</BaseText>
          <DescText>{row.role}</DescText>
        </>
      ),
    },
  ];

  return (
    <CustomTableWrapper style={{ marginTop: 22 }}>
      <Text fontSize={16} fontWeight={700}>
        ประวัติทำรายการ
      </Text>
      <div style={{ marginTop: 22 }}>
        <Table
          scroll={{ y: 600 }}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: (
              <div style={{ display: "flex", justifyContent: "center", padding: "6%" }}>
                <Empty
                  image={icon.emptyHistoryPoint}
                  description={
                    <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                      ไม่มีรายการประวัติ
                    </Text>
                  }
                />
              </div>
            ),
          }}
        />
      </div>
    </CustomTableWrapper>
  );
}

export default HistoryTab;

import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Image,
  Tag,
  Modal,
  message,
  Divider,
  Form,
  Collapse,
  Checkbox,
  ConfigProvider,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import Input from "../../../../components/Input/Input";
import { onInputNumberKeyPress } from "../../../../utility/validator";
import Text from "../../../../components/Text/Text";
import styled from "styled-components";
import color from "../../../../resource/color";
import icon from "../../../../resource/icon";
import { useWatch } from "antd/es/form/Form";
import { FlexCol, FlexRow } from "../../../../components/Container/Container";
import { numberFormatter } from "../../../../utility/Formatter";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Select from "../../../../components/Select/Select";
import { zoneDatasource } from "../../../../datasource/ZoneDatasource";
import Button from "../../../../components/Button/Button";
import { StoreEntity } from "../../../../entities/StoreEntity";
import TableContainer from "../../../../components/Table/TableContainer";
import image from "../../../../resource/image";
import {
  createThreshold,
  deleteDealerInGroup,
  getDealerInGroup,
  getThresholdById,
  updateThreshold,
} from "../../../../datasource/ThresholdDatasource";
import { ModalSelectStoreThreshold } from "../ModalSelecStoreTh";

const ErrorText = ({ children, hidden }: any) => {
  return !hidden ? (
    <Text color='error' level={5}>
      {children}
    </Text>
  ) : (
    <></>
  );
};
const redStar = <ErrorText>&nbsp;*</ErrorText>;

const InfoPanel = styled(Collapse)`
  :where(.css-dev-only-do-not-override-apn68).ant-collapse {
    border-radius: 4px !important;
  }
  .ant-collapse-header {
    font-family: "IBM Plex Sans Thai", "Helvetica";
    padding: 16px 20px !important;
  }
  .ant-collapse-content-box {
    padding: 0px 20px 16px !important;
    font-family: "Sarabun", "IBM Plex Sans Thai", "Helvetica";
  }
`;

const Example = ({ name, number, icon }: { name: string; number?: number; icon?: any }) => {
  return (
    <FlexRow style={{ marginTop: 8 }} align='center'>
      <img style={{ width: 16, height: 16, margin: "0 4px 4px 0" }} src={icon} />
      <Text style={{ width: 80 }}>{name}</Text>
      <Text align='right' style={{ width: 100 }}>
        {number || number === 0 ? numberFormatter(number, 2) : ""}
      </Text>
      <Text>&nbsp;ตัน</Text>
    </FlexRow>
  );
};

const CreateEdit: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { firstname, lastname, company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[4];
  const isEditing = !!id;

  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>();
  const [zoneFilter, setZoneFilter] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [dataState, setDataState] = useState<{
    groupData: any;
    dealerData: StoreEntity[];
    dealerCount: number;
  }>({
    groupData: null,
    dealerData: [],
    dealerCount: 0,
  });
  const [submiting, setSubmiting] = useState(false);
  const [percentage, setPercentage] = useState<number>();
  const [zoneData, setZone] = useState<{ label: string; value: string; key: string }[]>([]);
  const [stores, setStores] = useState<StoreEntity[]>([]);
  const [deleteStores, setDeleteStores] = useState<any[]>([]);

  const [showModalStore, setModalStore] = useState(false);
  const toggleModalStore = () => setModalStore(!showModalStore);

  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();

  useEffectOnce(() => {
    if (zoneData.length <= 0) {
      getZoneByCompany();
    }
    if (isEditing) {
      fetchData();
    }
  });

  useEffect(() => {
    let newStoreList: any[] = [...dataState.dealerData];
    if (zoneFilter) {
      newStoreList = newStoreList.filter((d) => d?.zone === zoneFilter);
    }
    if (keyword) {
      newStoreList = newStoreList.filter(
        (d) => d?.dealerCode.includes(keyword) || d?.dealerName.includes(keyword),
      );
    }
    setStores(newStoreList);
  }, [keyword, zoneFilter]);

  const fetchData = async () => {
    setLoading(true);
    try {
      let groupData, dealerData, dealerCount;
      await getThresholdById(id)
        .then((res) => {
          groupData = res?.responseData;
          form.setFieldsValue(res?.responseData);
          setPercentage(res?.responseData?.percent);
        })
        .catch((err) => {
          console.log(err);
        });

      await getDealerInGroup({
        examineGroupId: id,
        take: 1000,
      })
        .then((res) => {
          const data = res?.data?.map((d) => ({
            ...d,
            customerName: d.customerName || d.dealerName,
            customerNo: d.customerNo || d.dealerCode,
            customerCompanyId: d.customerCompanyId || d.dealerId,
          }));
          dealerData = data;
          dealerCount = res?.count;
          setStores(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });

      setDataState({
        groupData,
        dealerData,
        dealerCount,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZone(data);
  };

  const onSubmit = async () => {
    // check fields
    form
      .validateFields()
      .then((value) => {
        console.log(value);
        return;
      })
      .catch(() => {
        console.log("reject!");
        return;
      });
    setSubmiting(true);
    const { examineGroupName, percent } = form.getFieldsValue();
    const data = {
      examineGroupId: id ? id : undefined,
      examineGroupName,
      percent,
      company,
      updatedBy: `${firstname} ${lastname}`,
      examineGroupDealer: dataState.dealerData,
    };

    const callback = (res) => {
      // console.log("res", res);
      setDataState({
        groupData: null,
        dealerData: [],
        dealerCount: 0,
      });
      setStores([]);
      form.resetFields();
      navigate("/DetectOrderPage/threshold");
    };
    const callbackErr = (err) => {
      console.log(err);
    };
    const callbackFin = () => {
      setSubmiting(false);
    };

    if (isEditing) {
      if (deleteStores.length) {
        await await deleteDealerInGroup({
          examineGroupDealerId: deleteStores,
        });
      }
      await updateThreshold(data).then(callback).catch(callbackErr).finally(callbackFin);
    } else {
      await createThreshold(data).then(callback).catch(callbackErr).finally(callbackFin);
    }
  };

  const onDelete = () => {
    Modal.confirm({
      title: "ต้องการลบร้านค้า",
      okText: "ลบ",
      cancelText: "ย้อนกลับ",
      onOk: () => {
        const dealers = dataState?.dealerData.filter(
          (d1: any) => !stores?.find((d2: any) => d1?.dealerId === d2?.dealerId && d2?.isChecked),
        );
        const deleteDealer = dataState?.dealerData.filter(
          (d1: any) => stores?.find((d2: any) => d1?.dealerId === d2?.dealerId && d2?.isChecked),
        );
        setStores(dealers);
        setDataState({ ...dataState, dealerData: dealers, dealerCount: dealers.length });
        setZoneFilter(undefined);
        setKeyword("");
        filterForm.resetFields();
        setDeleteStores([
          ...deleteStores,
          ...deleteDealer
            .filter((d: any) => d.examineGroupDealerId)
            .map((d: any) => d.examineGroupDealerId),
        ]);
      },
    });
  };

  const handleCheckBox = (e: any, dealerId: number) => {
    const checkBoxed = stores.map((item: any) => ({
      ...item,
      isChecked: `${item.dealerId}` === `${dealerId}` ? e.target.checked : item.isChecked,
    }));
    setStores(checkBoxed);
  };

  const getWarningNumber = () => (percentage && !Number.isNaN(percentage) ? percentage * 5 : 0);

  const formSection = (
    <Form form={form} layout='vertical' onFinish={onSubmit}>
      <Text level={4} fontWeight={700}>
        ชื่อกลุ่มตรวจจับร้านค้า
      </Text>
      {redStar}
      <br />
      <ErrorText hidden>กรุณาระบุชื่อกลุ่มตรวจจับ</ErrorText>
      <Row>
        <Col span={12}>
          <Form.Item
            name='examineGroupName'
            noStyle
            rules={[{ required: true, message: "กรุณาระบุชื่อกลุ่มตรวจจับ" }]}
          >
            <Input placeholder='ระบุชื่อกลุ่มร้านค้า' />
          </Form.Item>
        </Col>
      </Row>
      <br />
      <br />
      <Text level={4} fontWeight={700}>
        % การตรวจจับยอดสั่งซื้อผิดปกติของ Sub-Dealer เมื่อเทียบกับยอดสั่งซื้อ Dealer
      </Text>
      {redStar}
      <br />
      <ErrorText hidden>กรุณาเลือก % การตรวจจับ</ErrorText>
      <Row>
        <Col span={12}>
          <Form.Item
            name='percent'
            noStyle
            rules={[
              { required: true, message: "กรุณาเลือก % การตรวจจับ" },
              {
                message: "กรุณากรอกตัวเลขระหว่าง 1-100",
                validator(rule, value, callback) {
                  const intVal = parseInt(value);
                  if (!value || (intVal > 0 && intVal <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("กรุณากรอกตัวเลขระหว่าง 1-100"));
                },
              },
            ]}
          >
            <Input
              placeholder='ระบุ % การตรวจจับ'
              suffix='%'
              onKeyDown={(e) => onInputNumberKeyPress(e, { onlyInt: true })}
              onChange={(e) => setPercentage(parseInt(e.target.value))}
              min={1}
              max={100}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  const infoPanel = (
    <InfoPanel
      bordered={false}
      expandIcon={({ isActive }) => (
        <img
          src={icon.caret}
          style={{
            width: 10,
            height: 5,
            transform: isActive ? undefined : "rotate(180deg)",
          }}
        />
      )}
      expandIconPosition='end'
      style={{ background: "#F3F6F9" }}
      defaultActiveKey={"1"}
      items={[
        {
          key: "1",
          label: <Text fontWeight={700}>แสดงตัวอย่างการตรวจจับ</Text>,
          children: (
            <>
              <Text>
                หากยอดสั่งซื้อร้าน Dealer = 400.00 ตัน + สต๊อกคงเหลือ = 100.00 ตัน ยอดรวมทั้งสิ้น =
                500.00 ตัน
              </Text>
              <br />
              <Text>
                โดยกำหนด % การตรวจจับ ={" "}
                <span style={{ fontWeight: 700 }}>
                  {Number.isNaN(percentage) ? "0" : percentage}%
                </span>{" "}
                ของยอดรวมของ Dealer
              </Text>
              <Divider style={{ margin: "8px 0px" }} />
              <Text>3 ระดับการแจ้งเตือนตามปริมาณ</Text>
              <br />
              <Example name='จับตามอง' number={getWarningNumber() * 0.9} icon={icon.dangerYellow} />
              <Example name='เริ่มผิดปกติ' number={getWarningNumber()} icon={icon.dangerOrange} />
              <Example name='ผิดปกติ' number={getWarningNumber() * 1.1} icon={icon.dangerRed} />
            </>
          ),
        },
      ]}
    />
  );

  const storeColumns = [
    {
      title: (
        <Checkbox
          onClick={() => {
            const isChecked = !stores.every((x) => x.isChecked);
            setStores(stores.map((item) => ({ ...item, isChecked })));
          }}
          checked={stores.length > 0 && stores.every((x) => x.isChecked)}
          indeterminate={stores.find((x) => x.isChecked) && !stores.every((x) => x.isChecked)}
        />
      ),
      width: "5%",
      dataIndex: "dealerId",
      key: "dealerId",
      render: (dealerId: any, value: any) => {
        return (
          <Checkbox
            checked={!!stores.find((s: any) => s.dealerId === dealerId && s.isChecked)}
            onClick={(e) => handleCheckBox(e, dealerId)}
          />
        );
      },
    },
    {
      title: "รหัสร้านค้า",
      dataIndex: "dealerCode",
      key: "dealerCode",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              <Text level={6} color='Text3'>
                {row?.customer?.taxNo}
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "ประเภทคู่ค้า",
      dataIndex: "userType",
      key: "userType",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text level={6}>{"Dealer"}</Text>,
        };
      },
    },
    {
      title: "เขตการขาย",
      dataIndex: "zone",
      key: "zone",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
  ];
  const storeSection = (
    <>
      <Text level={4} fontWeight={700}>
        เพิ่มร้านค้าเข้ากลุ่ม&nbsp;{redStar}
      </Text>
      <br />
      <FlexRow justify='space-between'>
        <Text level={5} color='Text3'>
          <ExclamationCircleOutlined />
          &nbsp;ร้านค้าที่ถูกเพิ่มในกลุ่มแล้ว ไม่สามารถเพิ่มซ้ำเข้ากลุ่มใหม่ได้
        </Text>
        <Text>ทั้งหมด&nbsp;{dataState.dealerCount}&nbsp;ร้านค้า</Text>
      </FlexRow>
      <br />
      <Form form={filterForm}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name='search'>
              <Input
                placeholder='รหัส / ชื่อร้านค้า'
                suffix={<SearchOutlined />}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name='zoneData'>
              <Select
                placeholder='เขตทั้งหมด'
                data={zoneData}
                // style={{ width: 150 }}
                onChange={(z) => setZoneFilter(z)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button
              title='ล้างการค้นหา'
              typeButton='primary-light'
              onClick={() => {
                setZoneFilter(undefined);
                setKeyword("");
                filterForm.resetFields();
              }}
            />
          </Col>
          <Col span={6} />
          <Col span={3}>
            <Button
              title='ลบร้านค้า'
              icon={<DeleteOutlined />}
              disabled={false}
              typeButton={
                //   !stores ||
                //   !stores.find((s) => s.isChecked)
                //     ? "disabled"
                //     :
                "border-light"
              }
              style={
                //   !stores ||
                //   !stores.find((s) => s.isChecked)
                //     ? {}
                //     :
                { color: color.error }
              }
              onClick={onDelete}
            />
          </Col>
          <Col span={3}>
            <Button
              title='เพิ่มร้านค้า'
              icon={<PlusOutlined />}
              typeButton='primary'
              onClick={toggleModalStore}
            />
          </Col>
        </Row>
      </Form>
      <TableContainer>
        <Table
          columns={storeColumns}
          dataSource={stores?.map((d: any) => ({
            ...d,
            key: d.examineGroupDealerId,
          }))}
          scroll={{ y: 600 }}
          locale={{
            emptyText: (
              <FlexCol
                align='center'
                justify='center'
                style={{ width: "100%", height: "100%", padding: "80px 32px" }}
              >
                <img src={image.emptyShopTable} style={{ width: 126, height: 80 }} />
                <br />
                <Text level={5} color='Text3'>
                  ไม่มีรายการร้านค้า
                </Text>
              </FlexCol>
            ),
          }}
          pagination={false}
          loading={loading}
        />
      </TableContainer>
      <ModalSelectStoreThreshold
        showModalShop={showModalStore}
        currentSelectShop={dataState?.dealerData || []}
        company={company}
        onClose={toggleModalStore}
        thisGroupId={isEditing ? id : undefined}
        callBackShop={(s: StoreEntity[]) => {
          const newDealers = s.map((d) => ({
            ...d,
            dealerId: d.customerCompanyId,
            dealerCode: d.customerNo,
            dealerName: d.customerName,
            userType: "SUB_DEALER",
            examineGroupId: id ? id : undefined,
          }));
          const dealers = [...dataState.dealerData, ...newDealers];
          setStores(dealers);
          setDataState({ ...dataState, dealerData: dealers, dealerCount: dealers.length });
          setZoneFilter(undefined);
          setKeyword("");
          toggleModalStore();
          filterForm.resetFields();
        }}
      />
    </>
  );

  const footer = (
    <Row justify='end' style={{ marginTop: 40 }}>
      <Col span={3}>
        <Button title='บันทึก' onClick={onSubmit} loading={submiting} />
      </Col>
    </Row>
  );

  return (
    <>
      {formSection}
      <br />
      {infoPanel}
      <Divider />
      {storeSection}
      {footer}
    </>
  );
};

export default CreateEdit;

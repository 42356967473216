import React, { useEffect, useState } from "react";
import {
  campaignShopDetail,
  campaignShopDetailOrder,
  reportCampaignShop,
} from "../../../../datasource/CampaignDatasource";
import { useNavigate } from "react-router-dom";
import { DealerType } from "../../../../definitions/campaign";
import { Col, Row, Table, Image, Tag } from "antd";
import Descriptions from "../../../../components/Description/Descriptions";
import Text from "../../../../components/Text/Text";
import moment from "moment";
import { FlexCol, FlexRow } from "../../../../components/Container/Container";
import { numberFormatter } from "../../../../utility/Formatter";
import { DownOutlined, SearchOutlined, StarFilled, UpOutlined } from "@ant-design/icons";
import GreyTable from "../../../../components/Table/GreyTable";
import { take } from "lodash";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import { getMapStatusWord, MAP_STATUS_COLOR } from "../../../../definitions/approvePoint";
import image from "../../../../resource/image";

const TabOrder: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageSize = 10;

  // Filter
  const [searchDocNo, setSearchDocNo] = useState<string>();
  const [searchStore, setSearchStore] = useState<string>();
  const [filterStatus, setStatus] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const clearFilter = () => {
    setSearchDocNo("");
    setSearchStore("");
    setStatus(undefined);
  };

  useEffect(() => {
    if (!loading) fetchData();
  }, []);

  useEffect(() => {
    // console.log("apply filter");
    const newList = data?.filter((d) => {
      console.log(d?.orderPointCode, searchDocNo);
      if (searchDocNo && (!d?.orderPointCode || !d?.orderPointCode.includes(searchDocNo))) {
        return false;
      }
      if (
        searchStore &&
        !d?.dealerName.includes(searchStore) &&
        !d?.dealerNo.includes(searchStore)
      ) {
        return false;
      }
      if (filterStatus && d?.status !== filterStatus) {
        return false;
      }
      return true;
    });
    setFilteredData(newList || []);
  }, [searchDocNo, searchStore, filterStatus]);

  const fetchData = async () => {
    setLoading(true);
    const type = pathSplit[3];
    const id = pathSplit[4];
    await campaignShopDetailOrder(type, id, page, 1000)
      .then((res) => {
        console.log("tab1", res);
        setData(res?.data);
        setCount(res?.count);
        setFilteredData(res?.data);
        clearFilter();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columnWidth = [180, 220, 360, 150, 110, 150, 150];
  const columns = [
    {
      title: "เลขที่ส่งเอกสาร",
      dataIndex: "orderPointCode",
      key: "orderPointCode",
      width: columnWidth[0],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.dateDelivery ? moment(row?.dateDelivery).format("DD/MM/YYYY") : ""}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "จำนวนสินค้า",
      dataIndex: "countProduct",
      key: "countProduct",
      width: columnWidth[1],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              รายการ
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ซื้อจากร้าน",
      dataIndex: "dealerName",
      key: "dealerName",
      width: columnWidth[2],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.dealerNo}&nbsp;•&nbsp;Dealer
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      width: columnWidth[3],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "จำนวนตัน",
      dataIndex: "sumAmount",
      key: "sumAmount",
      width: columnWidth[4],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      width: columnWidth[5],
      render: (value, row) => {
        return (
          <Tag style={{ fontWeight: 700, borderRadius: 16 }} color={MAP_STATUS_COLOR[value]}>
            <Text color='white' level={6} fontWeight={600}>
              {getMapStatusWord("SUB_DEALER")[value] || ""}
            </Text>
          </Tag>
          //   <Text>{`${row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""} - ${
          //     row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""
          //   }`}</Text>
        );
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: columnWidth[6],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const expandColumns = [
    {
      title: "",
      dataIndex: "",
      key: "campaignProgramId",
      width: columnWidth[0],
    },
    {
      title: "",
      dataIndex: "productName",
      key: "productName",
      width: columnWidth[1] + columnWidth[2] - 150,
      render: (productName: string, row: any) => {
        return {
          children: (
            <FlexRow align='center'>
              <div style={{ marginRight: 16 }}>
                <Image
                  src={row?.productImage || image.product_no_image}
                  style={{
                    width: "55px",
                    height: "55px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <FlexCol>
                <Text level={5}>{productName}</Text>
                <Text level={6} color='Text3'>
                  {row?.productCommonName}
                </Text>
                <Text level={6}>
                  <StarFilled style={{ color: "#F4BF00", fontSize: 14 }} />
                  &nbsp;{numberFormatter(row?.point, 2)}&nbsp;แต้ม&nbsp;/&nbsp;ตัน
                </Text>
              </FlexCol>
            </FlexRow>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 150,
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{row?.saleUom}</Text>
            <Text level={6} color='Text3'>
              {row?.productCode}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "",
      dataIndex: "quantity",
      key: "quantity",
      width: columnWidth[3],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "",
      dataIndex: "amount",
      key: "amount",
      width: columnWidth[4],
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      width: columnWidth[5],
      render: (value, row) => {
        return <Text>{getMapStatusWord("SUB_DEALER")[value] || ""}</Text>;
      },
    },
    {
      title: "",
      dataIndex: "programPoint",
      key: "programPoint",
      width: columnWidth[6],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "",
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: 48 + 8,
    },
  ];

  const expandedRowRender = (expandDataSource) => (
    <GreyTable
      columns={expandColumns}
      dataSource={expandDataSource}
      pagination={false}
      showHeader={false}
    />
  );
  return (
    <>
      <Row align='bottom' gutter={16}>
        <Col span={5}>
          <Input
            placeholder='เลขที่เอกสาร'
            suffix={<SearchOutlined />}
            onChange={(e) => setSearchDocNo(e.target.value)}
          />
        </Col>
        <Col span={5}>
          <Input
            placeholder='ค้นหารหัส / ชื่อร้านค้า'
            suffix={<SearchOutlined />}
            onChange={(e) => setSearchStore(e.target.value)}
          />
        </Col>
        <Col span={4} style={{ marginBottom: 8 }}>
          <Select
            data={Object.entries(getMapStatusWord("SUB_DEALER")).map(([key, label]) => ({
              key,
              label,
              value: key,
            }))}
            placeholder='สถานะทั้งหมด'
            onChange={(val) => setStatus(val)}
          />
        </Col>
        <Col span={6} />
        <Col span={4}>
          <Row justify='end' align='bottom'>
            <Text>ทั้งหมด&nbsp;{count}&nbsp;รายการ</Text>
          </Row>
        </Col>
      </Row>
      <br />
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        // dataSource={data?.map((d, i) => ({ ...d, key: i })) || []}
        dataSource={filteredData?.map((d: any, i: number) => ({ ...d, key: i })) || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize,
          current: page,
          //   total: count || 0,
          total: filteredData?.length || 0,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
        expandable={{
          fixed: "right",
          expandedRowRender: (r) => expandedRowRender(r?.campaignProduct),
          rowExpandable: (r) => r?.campaignProduct?.length,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </>
  );
};

export default TabOrder;

import { Tabs } from "antd";
import BreadCrumb from "../../../../components/BreadCrumb/BreadCrumb";
import { CardContainer } from "../../../../components/Card/CardContainer";
import PageTitleNested from "../../../../components/PageTitle/PageTitleNested";
import { useState } from "react";
import DetailTab from "./DetailTab";
import HistoryTab from "./HistoryTab";
import Text from "../../../../components/Text/Text";
import { useParams } from "react-router-dom";
import { getOrderCheckById } from "../../../../datasource/orderCheckDatasource";
import { useQuery } from "react-query";

type tabKey = "detail" | "history";

const DetectionDetail: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<tabKey>("detail");
  const isDetailTab = selectedTab === "detail";
  const { id } = useParams();
  // const id = window.location.pathname.split("/")[3];
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [refreshDetail, setRefreshDetail] = useState<boolean>(false);

  const { data } = useQuery(
    ["detailTab", id, refreshDetail],
    async () => {
      if (selectedTab === "detail" && !!id) {
        return await getOrderCheckById(id);
      }
    },
    { enabled: selectedTab === "detail" && !!id, refetchOnWindowFocus: false },
  );

  const tabs = [
    {
      label: (
        <Text
          fontSize={14}
          fontWeight={isDetailTab ? 600 : 400}
          color={isDetailTab ? "primary" : "Text1"}
        >
          รายละเอียด
        </Text>
      ),
      key: "detail",
      children: <DetailTab data={data} setRefresh={setRefreshDetail} id={id || ""} />,
    },
    {
      label: (
        <Text
          fontSize={14}
          fontWeight={!isDetailTab ? 600 : 400}
          color={!isDetailTab ? "primary" : "Text1"}
        >
          ประวัติบันทึกการตรวจสอบ
        </Text>
      ),
      key: "history",
      children: (
        <HistoryTab
          page={historyPage}
          setPage={setHistoryPage}
          selectedTab={selectedTab}
          id={id || ""}
        />
      ),
    },
  ];

  return (
    <CardContainer>
      <PageTitleNested
        showBack
        title={`ร้านค้า : ${data?.dealerName || "กำลังโหลด.."}`}
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการตรวจจับยอดสั่งซื้อ",
                path: -1,
              },
              { text: "รายละเอียดร้านค้า", path: window.location.pathname },
            ]}
          />
        }
      />
      <br />
      <Tabs
        items={tabs}
        onChange={(key: string) => {
          setSelectedTab(key as tabKey);
          if (key === "history") {
            setHistoryPage(1);
          }
        }}
        activeKey={selectedTab}
        defaultActiveKey='detail'
      />
    </CardContainer>
  );
};

export default DetectionDetail;

import React from "react";
import { Form } from "antd";
import Modal from "../../../../components/Modal/Modal";
import TextArea from "../../../../components/Input/TextArea";
import { Flex } from "./styled";
import Button from "../../../../components/Button/Button";
import Text from "../../../../components/Text/Text";
import { postHistoryCheck } from "../../../../datasource/orderCheckDatasource";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
}

interface FormValues {
  detail: string;
}

const AddRecordModal: React.FC<Props> = ({ open, setOpen, id, setRefresh }) => {
  const [form] = Form.useForm();

  const onCloseModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFinish = async (values: FormValues) => {
    try {
      const profile = JSON.parse(localStorage?.getItem("profile") || "");
      const payload = {
        orderCheckId: id,
        detail: values.detail,
        user: `${profile?.firstname} ${profile?.lastname}`,
        userPosition: profile?.role,
      };
      await postHistoryCheck(payload);
      setRefresh((prev) => !prev);
      onCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal onCancel={onCloseModal} visible={open} width={600}>
      <Text level={2}>บันทึกการตรวจสอบ</Text>
      <Form onFinish={onFinish} form={form}>
        <br />
        <Form.Item name='detail' rules={[{ required: true, message: "กรุณาระบุบันทึกการตรวจสอบ" }]}>
          <TextArea autoSize={{ minRows: 8 }} placeholder='ระบุข้อความบันทึก...' />
        </Form.Item>
        <Flex gap={16}>
          <Button title='ยกเลิก' typeButton='primary-light' onClick={onCloseModal} />
          <Button title='บันทึก' htmlType='submit' />
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddRecordModal;

import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const getCampaignStock = async (
  company: string,
  status: string,
  page: number,
  take: number,
  search?: string,
  startDate?: string,
  endDate?: string,
) => {
  const params = {
    company: company,
    status: status,
    search: search,
    startDate: startDate,
    endDate: endDate,
    page: page,
    take: take,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/stock/campaign-stock`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const getCampaignStockDetail = async (campaignId: string) => {
  return await httpClient
    .get(`${BASE_URL}/crm/stock/campaign-detail-stock/${campaignId}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const getCampaignStockHistory = async (stockId: string) => {
  return await httpClient
    .get(`${BASE_URL}/crm/stock/history-upload-stock/${stockId}`)
    .then((res: AxiosResponse) => {
      return res.data.responseData;
    })
    .catch((err) => console.log(err));
};
const getCampaignStockDealer = async (
  campaignId: string,
  page: number,
  take: number,
  search?: string,
  zone?: string,
  sortBy?: string,
  sortDirection?: string,
) => {
  const params = {
    campaignId: campaignId,
    page: page,
    take: take,
    search: search,
    zone: zone,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/stock/campaign-stock-dealer`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const downLoadTemplate = async (params: object): Promise<ArrayBuffer | undefined> => {
  return await httpClient
    .post(`${BASE_URL}/crm/stock/download-template`, params, {
      responseType: "arraybuffer",
    })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => {
      console.log(err);
      return undefined;
    });
};
const upLoadTemplate = async (params: {
  file: File;
  stockId?: string;
  [key: string]: any;
}): Promise<ArrayBuffer | undefined> => {
  try {
    const formData = new FormData();
    params.stockId && formData.append("stockId", params.stockId);
    formData.append("file", params.file);
    formData.append("campaignId", params.campaignId);
    formData.append("company", params.company);
    formData.append("updatedBy", params.updatedBy);
    formData.append("role", params.role);
    const response = await httpClient.post(`${BASE_URL}/crm/stock/upload-template`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
    });
    return response.data;
  } catch (err) {
    console.error("Failed to upload file:", err);
    return undefined;
  }
};
const getStockShopDetail = async (dealerId: string, stockId?: string) => {
  const params = {
    dealerId: dealerId,
    stockId: stockId,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/stock/stock-shop-detail`, { params })
    .then((res: AxiosResponse) => {
      return res.data?.responseData;
    })
    .catch((err) => console.log(err));
};
const getStockShopProduct = async (
  dealerId: string,
  stockShopId?: string,
  page?: number,
  take?: number,
) => {
  const params = {
    dealerId: dealerId,
    stockShopId: stockShopId,
    page: page,
    take: take,
  };
  return await httpClient
    .get(`${BASE_URL}/crm/stock/stock-shop-product`, { params })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
export {
  getCampaignStock,
  getCampaignStockDetail,
  getCampaignStockDealer,
  downLoadTemplate,
  upLoadTemplate,
  getCampaignStockHistory,
  getStockShopDetail,
  getStockShopProduct,
};

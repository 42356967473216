import React, { JSX, useEffect, useState } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Spin, Tabs } from "antd";
import PageTitleNested from "../../../components/PageTitle/PageTitleNested";
import { useNavigate } from "react-router-dom";
import { FlexCol } from "../../../components/Container/Container";
import Text from "../../../components/Text/Text";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";
import color from "../../../resource/color";
import DetailTab from "./Tabs/DetailTab";
import HistoryTab from "./Tabs/HistoryTab";
import ModalSendTemplate from "../../../components/Modal/ModalSendTemplate";
import {
  getCampaignStockDetail,
  getCampaignStockHistory,
} from "../../../datasource/StockDatasource";
import {
  StockDetailEntities,
  StockDetailEntities_INIT,
  StockHistoryEntities,
  StockHistoryEntities_INIT,
} from "../../../entities/CampaignStockEntity";

function EditStockSetting() {
  const campaignId = window.location.pathname.split("/")[3];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState<StockHistoryEntities[]>([
    StockHistoryEntities_INIT,
  ]);
  const [detail, setDetail] = useState<StockDetailEntities>(StockDetailEntities_INIT);
  const [modalSendEmail, setModalSendEmail] = useState(false);
  const [stockId, setStockId] = useState<string>("");

  useEffect(() => {
    fetchStockDetail();
  }, []);

  const fetchStockDetail = async () => {
    setLoading(true);
    try {
      await getCampaignStockDetail(campaignId).then((res) => {
        setDetail(res);
        if (res.stock) {
          setStockId(res.stock?.stockId);
        }
      });
    } catch (error) {
      console.log("err campaign stock detail", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchStockHistory = async () => {
    setLoading(true);
    try {
      await getCampaignStockHistory(stockId).then((res) => {
        setHistoryData(res);
      });
    } catch (error) {
      console.log("err campaign stock history", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTemplate = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setModalSendEmail(true);
    }, 300);
  };
  const PageTitle = () => {
    return (
      <PageTitleNested
        title='แก้ไขสต๊อกร้านค้า'
        showBack
        onBack={() => navigate(-1)}
        extra={
          <FlexCol align='end'>
            <Button
              title='ดาวน์โหลดเทมเพลตไฟล์นำเข้าสต๊อก'
              icon={
                <img
                  src={icon.downloadFileIcon}
                  style={{ width: 22, height: 26, color: color.BK, paddingBottom: 4 }}
                />
              }
              typeButton='primary-light'
              onClick={handleDownloadTemplate}
            />
          </FlexCol>
        }
        customBreadCrumb={
          <BreadCrumb
            data={[
              {
                text: "รายการตั้งค่าสต๊อกร้านค่า",
                path: -1,
              },
              { text: "แก้ไขสต๊อกร้านค้า", path: window.location.pathname },
            ]}
          />
        }
      />
    );
  };

  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      key: "DETAIL",
      label: (
        <Text fontSize={14} fontWeight={400}>
          รายละเอียด
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <DetailTab data={detail} newFetch={() => fetchStockDetail()} />
      ),
    },
    {
      key: "HISTORY",
      label: (
        <Text fontSize={14} fontWeight={400}>
          ประวัตินำเข้าสต๊อก
        </Text>
      ),
      children: loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <HistoryTab data={historyData} />
      ),
    },
  ];
  const handleTabChange = (key: string) => {
    if (key === "HISTORY") {
      fetchStockHistory();
    }
  };
  return (
    <CardContainer>
      <PageTitle />
      <div style={{ padding: 22 }}>
        <Tabs items={tabsData} onChange={handleTabChange} />
      </div>
      <ModalSendTemplate
        visible={modalSendEmail}
        onSubmit={() => setModalSendEmail(false)}
        detail={detail}
      />
    </CardContainer>
  );
}

export default EditStockSetting;

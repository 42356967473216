import color from "../resource/color";

export const MAP_STATUS = [
  { key: 1, value: "WAIT_APPROVE", label: "รออนุมัติ" },
  { key: 2, value: "APPROVE", label: "อนุมัติ" },
  { key: 3, value: "REJECT", label: "ไม่อนุมัติ" },
  { key: 4, value: "CANCEL", label: "ยกเลิก" },
];
export const MAP_STATUS_CHECK = [
  { key: 1, value: "ALL", label: "การตรวจสอบทั้งหมด" },
  { key: 2, value: "NON_CHECK", label: "รอการตรวจสอบ" },
  { key: 3, value: "CHECK", label: "ตรวจสอบแล้ว" },
];
export const MAP_STATUS_COLOR: any = {
  WAIT_APPROVE: color["warning"],
  APPROVE: color["success"],
  REJECT: color["error"],
  CANCEL: color["placeholder"],
};
export const MAP_STATUS_WORD: any = {
  WAIT_APPROVE: "รออนุมัติ",
  APPROVE: "อนุมัติ",
  REJECT: "ไม่อนุมัติ",
  CANCEL: "ยกเลิก",
};
export const MAP_USER_TYPE: any = {
  SUB_DEALER: "Sub-Dealer",
  DEALER: "Dealer",
  ADMIN: "Admin",
};

type StatusType = "WAIT_APPROVE" | "APPROVE" | "REJECT" | "CANCEL" | "DRAFT";
export const MAP_STATUS_CRM: any = {
  WAIT_APPROVE: color["warning"],
  APPROVE: color["success"],
  REJECT: color["error"],
  CANCEL: color["grayishBlue"],
  DRAFT: color["warning"],
};
export const getMapStatusWord = (type: "SUB_DEALER" | "DEALER") => {
  const MAP_STATUS_WORD: Record<StatusType, string> =
    type === "DEALER"
      ? {
          WAIT_APPROVE: "รอรับแต้ม",
          APPROVE: "ได้รับแต้ม",
          REJECT: "ไม่อนุมัติแต้ม",
          CANCEL: "ยกเลิกแต้ม",
          DRAFT: "รอรับแต้ม",
        }
      : {
          WAIT_APPROVE: "รออนุมัติ",
          APPROVE: "อนุมัติแต้ม",
          REJECT: "ไม่อนุมัติ",
          CANCEL: "ยกเลิก",
          DRAFT: "รออนุมัติ",
        };

  return MAP_STATUS_WORD;
};

export interface CampaignStockEntities {
  campaignCode: string;
  campaignId: string;
  campaignName: string;
  countDealer: string;
  countDealerAll: string;
  endDate: string;
  isApproved: boolean;
  isDeleted: boolean;
  isEnabled: boolean;
  startDate: string;
  status: string;
  updateAt: string;
  updateBy: string;
}
export const CampaignStockEntities_INIT: CampaignStockEntities = {
  campaignCode: "",
  campaignId: "",
  campaignName: "",
  countDealer: "",
  countDealerAll: "",
  endDate: "",
  isApproved: false,
  isDeleted: false,
  isEnabled: false,
  startDate: "",
  status: "",
  updateAt: "",
  updateBy: "",
};
export interface AllCampaignStockEntities {
  count: number;
  data: CampaignStockEntities[];
  statusCount: number;
}
export const AllCampaignStockEntities_INIT: AllCampaignStockEntities = {
  count: 0,
  data: [CampaignStockEntities_INIT],
  statusCount: 0,
};

//campaign stock detail
export interface StockEntities {
  pathFile: string;
  stockId: string;
  updateAt: string;
  updatedBy: string;
}
export const StockEntities_INIT: StockEntities = {
  pathFile: "",
  stockId: "",
  updateAt: "",
  updatedBy: "",
};
export interface StockDetailEntities {
  campaignCode: string;
  campaignId: string;
  campaignName: string;
  countDealer: string;
  endDate: string;
  startDate: string;
  stock: StockEntities;
}
export const StockDetailEntities_INIT: StockDetailEntities = {
  campaignCode: "",
  campaignId: "",
  campaignName: "",
  countDealer: "",
  endDate: "",
  startDate: "",
  stock: StockEntities_INIT,
};
export interface StockDealerEntities {
  stockShopId: string;
  dealerNo: string;
  dealerName: string;
  zone: string;
  sumQuantity: string;
  dealerId: number;
  countProduct: string;
}
export const StockDealerEntities_INIT: StockDealerEntities = {
  stockShopId: "",
  dealerNo: "",
  dealerName: "",
  zone: "",
  sumQuantity: "",
  dealerId: 0,
  countProduct: "",
};
export interface AllStockDealerEntities {
  count: number;
  statusImport: boolean;
  data: StockDealerEntities[];
}
export const AllStockDealerEntities_INIT: AllStockDealerEntities = {
  count: 0,
  statusImport: false,
  data: [StockDealerEntities_INIT],
};
export interface StockHistoryEntities {
  activity: string;
  createAt: string;
  historyUploadStockId: string;
  role: string;
  stockId: string;
  updateAt: string;
  updatedBy: string;
}
export const StockHistoryEntities_INIT: StockHistoryEntities = {
  activity: "",
  createAt: "",
  historyUploadStockId: "",
  role: "",
  stockId: "",
  updateAt: "",
  updatedBy: "",
};
export interface StockShopDetailEntities {
  dealerId: string;
  dealerCode: string;
  dealerName: string;
  zone: string;
  sumQuantity: string;
  sumQuantityNav: string;
  stockShopId: string;
  countProduct: string;
}
export const StockShopDetailEntities_INIT: StockShopDetailEntities = {
  dealerId: "",
  dealerCode: "",
  dealerName: "",
  zone: "",
  sumQuantity: "",
  sumQuantityNav: "",
  stockShopId: "",
  countProduct: "",
};
export interface StockShopProductEntities {
  productId: string;
  productName: string;
  quantity: string;
  quantityNav: string;
  productCodeNav: string;
  commonName: string;
  saleUOM: string;
  productImage: string;
}
export const StockShopProductEntities_INIT: StockShopProductEntities = {
  productId: "",
  productName: "",
  quantity: "",
  quantityNav: "",
  productCodeNav: "",
  commonName: "",
  saleUOM: "",
  productImage: "",
};
export interface AllStockShopProductEntities {
  count: number;
  data: StockShopProductEntities[];
}
export const AllStockShopProductEntities_INIT: AllStockShopProductEntities = {
  count: 0,
  data: [StockShopProductEntities_INIT],
};

import React, { useState } from "react";
import TablePagination from "../../../../../components/Table/TablePagination";
import Text from "../../../../../components/Text/Text";
import icon from "../../../../../resource/icon";
import Input from "../../../../../components/Input/Input";
import { SearchOutlined } from "@ant-design/icons";
import color from "../../../../../resource/color";
import { Flex } from "../styled";
import SortFieldCustom from "../../../../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../../../../hook/useSorting";
import { useQuery } from "react-query";
import { getProductCheck } from "../../../../../datasource/orderCheckDatasource";
import { ProductCheckEntities } from "../../../../../entities/OrderCheckEntity";

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedTable: string;
  id: string;
}

const ProductTable: React.FC<Props> = ({ page, setPage, selectedTable, id }) => {
  const take = 10;
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();

  const { data /* ,isLoading */ } = useQuery(
    ["products", selectedTable, id, page, searchText, sortField, sortDirection],
    async () => {
      if (selectedTable === "product" && !!id) {
        return await getProductCheck({
          page,
          take,
          orderCheckId: id,
          search: searchText,
          sortBy: sortField,
          sortDirection,
        });
      }
    },
    { enabled: selectedTable === "product" && !!id, refetchOnWindowFocus: false },
  );

  const columns = [
    {
      key: "product",
      dataIndex: "productImage",
      title: (
        <Text level={5} fontWeight={700}>
          สินค้า
        </Text>
      ),
      render: (img, record: ProductCheckEntities) => (
        <Flex gap={12}>
          <img
            src={img || icon.emptyProduct}
            alt='product-img'
            width={50}
            height={50}
            style={{
              objectFit: "contain",
              borderRadius: 6,
            }}
          />
          <Flex flexDirection='column'>
            <Text fontFamily='Sarabun' level={5}>
              {record?.productName}
            </Text>
            <Text fontFamily='Sarabun' level={5} color='Text3'>
              {record?.productCommonName}
            </Text>
          </Flex>
        </Flex>
      ),
    },
    {
      key: "saleUom",
      dataIndex: "saleUom",
      width: 250,
      title: (
        <Text level={5} fontWeight={700}>
          ขนาด
        </Text>
      ),
      render: (text, record) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            {record?.productCodeNav}
          </Text>
        </Flex>
      ),
    },
    {
      key: "dealerOrder",
      dataIndex: "dealerOrder",
      width: 250,
      title: (
        <SortFieldCustom
          title='ยอดสั่งซื้อ Dealer'
          sortDirection={sortField === "dealerOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("dealerOrder")}
        />
      ),
      render: (text) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            ตัน
          </Text>
        </Flex>
      ),
    },
    {
      key: "subDealerOrder",
      dataIndex: "subDealerOrder",
      width: 250,
      title: (
        <SortFieldCustom
          title='ยอดสั่งซื้อ Sub-Dealer'
          sortDirection={sortField === "subDealerOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("subDealerOrder")}
        />
      ),
      render: (text) => (
        <Flex flexDirection='column'>
          <Text fontFamily='Sarabun' level={5}>
            {text}
          </Text>
          <Text fontFamily='Sarabun' level={5} color='Text3'>
            ตัน
          </Text>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex justifyContent='space-between' paddingBottom={16} alignItems='end'>
        <Input
          placeholder='รหัส / ชื่อสินค้า'
          suffix={<SearchOutlined style={{ color: color.Text3 }} />}
          defaultValue={searchText}
          onPressEnter={(e) => {
            const value = (e.target as HTMLTextAreaElement).value;
            setSearchText(value);
            setPage(1);
          }}
          onChange={(e) => {
            const value = (e.target as HTMLInputElement).value;
            if (!value) {
              setSearchText(undefined);
            }
          }}
          style={{ width: 300 }}
        />
        <Text level={6}>{`ทั้งหมด ${data?.count || 0} รายการ`}</Text>
      </Flex>
      <TablePagination
        // isLoading={isLoading}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 32,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 24,
                minHeight: 350,
              }}
            >
              <img
                src={icon.emptyHistoryPoint}
                style={{
                  width: 80,
                  height: 90,
                }}
                alt='empty_table'
              />
              <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                ไม่มีรายการสินค้า
              </Text>
            </div>
          ),
        }}
        columns={columns}
        data={data?.data || []}
        scroll={{ x: "max-content" }}
        pagination={{
          current: page,
          pageSize: take,
          total: data?.count,
          onChange: (p) => {
            setPage(p);
          },
        }}
        isQuickJumper
        onPageChange={(pageNum) => setPage(pageNum)}
      />
    </>
  );
};

export default ProductTable;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DealerType } from "../../../../definitions/campaign";
import {
  reportCampaignShop,
  reportOrderDetailShop,
} from "../../../../datasource/CampaignDatasource";
import { Col, Row, Table } from "antd";
import Descriptions from "../../../../components/Description/Descriptions";
import Text from "../../../../components/Text/Text";
import { FlexCol, FlexRow } from "../../../../components/Container/Container";
import { numberFormatter } from "../../../../utility/Formatter";
import Input from "../../../../components/Input/Input";
import { SearchOutlined } from "@ant-design/icons";

const TabProduct: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const [filteredData, setFilteredData] = useState<any>([]);

  useEffect(() => {
    if (!loading) fetchData();
  }, []);

  useEffect(() => {
    const newList = data?.filter((d) => {
      if (search && !d?.productName.includes(search) && !d?.productCommonName.includes(search)) {
        return false;
      }
      return true;
    });
    setFilteredData(newList || []);
  }, [search]);

  const fetchData = async () => {
    setLoading(true);
    const type = pathSplit[3];
    const id = pathSplit[4];
    await reportOrderDetailShop({
      customerCompanyId: parseInt(id),
      userType: type?.toUpperCase(),
      page: 1,
      take: 1000,
    })
      .then((res) => {
        console.log(res);
        let data: any[] = [];
        res?.dataResult?.forEach((d) => {
          d?.product?.forEach((p) => {
            data.push(p);
          });
        });
        (data = data
          ?.filter((p1, i) => data.findIndex((p2) => p1.productId === p2.productId) === i)
          .map((p, key) => ({ ...p, key }))),
          setData(data);
        setFilteredData(data);
        setCount(res.totalQty);
        setSearch("");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "สินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value, row) => {
        return (
          <FlexRow>
            <FlexCol>
              <img
                src={row?.productImage}
                style={{ width: 50, height: 50, objectFit: "contain" }}
              />
            </FlexCol>
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                {row?.productCommonName}
              </Text>
            </FlexCol>
          </FlexRow>
        );
      },
    },
    {
      title: "ขนาด",
      dataIndex: "saleUom",
      key: "saleUom",
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              {row?.unitName}
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      render: (value, row) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              ตัน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ยอดแต้ม",
      dataIndex: "sumPoint",
      key: "sumPoint",
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
  ];
  return (
    <>
      <Row style={{ margin: "16px 0 20px" }}>
        {/* <Col span={12}>
          <Descriptions label='ปริมาณที่สั่งซื้อทั้งหมด' value={count + " ตัน"} noColon />
        </Col> */}
        <Col span={5}>
          <Input
            suffix={<SearchOutlined />}
            placeholder='รหัส/ชื่อสินค้า'
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={9} />
        <Col span={10}>
          <Row align='bottom' justify='end' style={{ height: "100%" }}>
            {/* <Text>ทั้งหมด&nbsp;{count || 0}&nbsp;รายการ</Text> */}
            <Text>ทั้งหมด&nbsp;{data?.length || 0}&nbsp;รายการ</Text>
          </Row>
        </Col>
      </Row>
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={filteredData || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 8,
          current: page,
          total: data?.length || 0,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
      />
    </>
  );
};

export default TabProduct;

import React, { useEffect, useState } from "react";
import { CardContainer, OuterCardContainer } from "../../../components/Card/CardContainer";
import { Col, Divider, Row, Tabs } from "antd";
import TablePagination from "../../../components/Table/TablePagination";
import icon from "../../../resource/icon";
import Text from "../../../components/Text/Text";
import CustomAutoComplete from "../../../components/AutoComplete/CustomAutoComplete";
import { CardSummary } from "../../../components/Card/CardSummary";
import color from "../../../resource/color";
import {
  InfoCircleFilled,
  InfoCircleOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  WarningFilled,
} from "@ant-design/icons";
import Input from "../../../components/Input/Input";
import Select from "../../../components/Select/Select";
import { zoneDatasource } from "../../../datasource/ZoneDatasource";
import SelectCheckbox from "../../../components/Select/SelectCheckbox";
import {
  DANGER_WORD_COLOR,
  MAP_DANGER_LEVEL,
  STATUS_DANGER_COLOR,
  STATUS_DANGER_ICON,
  STATUS_DANGER_WORD,
} from "../../../definitions/dangerLevel";
import { useNavigate } from "react-router-dom";
import { BaseText, DescText } from "../../../components/Text/Label";
import moment from "moment";
import SortFieldCustom from "../../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../../hook/useSorting";
import { numberFormatter } from "../../../utility/Formatter";
import TotalOrder from "../../../components/Popover/OrderTrackingList/TotalOrder";
import StatusBadge from "../../../components/Badge/StatusBadge";
import DetailStatusDanger from "../../../components/Popover/OrderTrackingList/DetailStatusDanger";
import ModalPartnerList from "../../../components/Modal/ModalPartnerList";
import {
  AllOrderCheckEntities,
  AllOrderCheckEntities_INIT,
} from "../../../entities/OrderCheckEntity";
import { getOrderCheckDatasource } from "../../../datasource/orderCheckDatasource";
import { getCampaignStock } from "../../../datasource/StockDatasource";
import {
  AllCampaignStockEntities_INIT,
  type AllCampaignStockEntities,
} from "../../../entities/CampaignStockEntity";

function DetectionList() {
  const company = JSON.parse(localStorage.getItem("company")!);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [campaignPage, setCampaignPage] = useState(1);
  const [searchCampaign, setSearchCampaign] = useState<any>();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [searchZone, setSearchZone] = useState<string | undefined>(undefined);
  const [zone, setZone] = useState<any>([]);
  const [selectDanger, setSelectDanger] = useState<any[]>([]);
  const navigate = useNavigate();
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<AllOrderCheckEntities>(AllOrderCheckEntities_INIT);
  const [isAbnormal, setIsAbnormal] = useState<boolean>();
  const [dataSearch, setDataSearch] = useState<AllCampaignStockEntities>(
    AllCampaignStockEntities_INIT,
  );
  const [orderCheckId, setOrderCheckId] = useState<string>("");
  const [startEndTime, setStartEndTime] = useState<string>("");
  const checkCampOrderId = localStorage.getItem("searchCampOrder");
  const checkCampOrderName = localStorage.getItem("searchCampName");
  const checkCampOrderTimeDate = localStorage.getItem("searchCampTimeDate");

  useEffect(() => {
    const getZone = async () => {
      await zoneDatasource.getAllZoneByCompany(company?.companyCode).then((res) => {
        setZone(res);
      });
    };
    getZone();
  }, []);

  useEffect(() => {
    handleCampaigns(true);
  }, []);

  const handleCampaigns = async (firstTime: boolean) => {
    setTimeout(async () => {
      try {
        const shouldFetchNewData = dataSearch.count !== dataSearch.data?.length;
        const res = shouldFetchNewData
          ? await getCampaignStock(company?.companyCode, "", firstTime ? 1 : campaignPage + 1, 10)
          : { count: dataSearch.count, data: [], statusCount: 0 };

        const sortedData = res.data.sort((a, b) => {
          if (a.status === "RUNNING" && b.status !== "RUNNING") return -1;
          return 1;
        });
        if (!firstTime) {
          setDataSearch({
            data: [...dataSearch.data, ...sortedData],
            count: res.count,
            statusCount: res.statusCount,
          });
          setCampaignPage(campaignPage + 1);
        } else {
          setDataSearch({
            data: res.data,
            count: res.count,
            statusCount: res.statusCount,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 300);
  };

  useEffect(() => {
    getOrderCheckList();
  }, [isAbnormal, searchZone, searchText, selectDanger, sortDirection, page, checkCampOrderId]);

  const getOrderCheckList = async () => {
    setLoading(true);
    try {
      await getOrderCheckDatasource(
        page,
        10,
        company?.company,
        checkCampOrderId ? checkCampOrderId : searchCampaign?.campaignId,
        searchText,
        searchZone,
        isAbnormal,
        selectDanger.flatMap((el) => el.value),
        sortField,
        sortDirection,
      ).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSearch.count > 0 && !searchCampaign) {
      setSearchCampaign(dataSearch.data[0]);
      localStorage.setItem("searchCampOrder", dataSearch.data[0].campaignId);
      localStorage.setItem("searchCampName", dataSearch.data[0].campaignName);
      const timeDate = `${moment(dataSearch.data[0].startDate).format("DD/MM/YYYY")}-${moment(
        dataSearch.data[0].endDate,
      ).format("DD/MM/YYYY")}`;
      localStorage.setItem("searchCampTimeDate", timeDate);
      setStartEndTime(timeDate);
    }
  }, [dataSearch.data, searchCampaign]);

  const optionsData = dataSearch.data.map((item) => {
    const formattedDateRange =
      moment(item.startDate).format("DD/MM/YYYY") +
      " - " +
      moment(item.endDate).format("DD/MM/YYYY");
    let statusIcon;
    if (item.status === "RUNNING") {
      statusIcon = icon.activeTime;
    } else if (item.status === "READY") {
      statusIcon = icon.pendingTime;
    } else {
      statusIcon = undefined;
    }

    return {
      data: item,
      value: item.campaignName,
      code: item.campaignCode,
      label: (
        <Row gutter={{ xs: 8 }}>
          <Col className='gutter-row' span={12}>
            <Text fontSize={14} fontWeight={400} fontFamily='Sarabun'>
              {item.campaignName.length > 22
                ? `${item.campaignName.slice(0, 22)}...`
                : item.campaignName}
            </Text>
            <br />
            <Text fontSize={12} color='Text3' fontFamily='Sarabun'>
              {item.campaignCode}
            </Text>
          </Col>
          <Col
            className='gutter-row'
            span={12}
            style={{ display: "flex", alignItems: "end", justifyContent: "end" }}
          >
            <Text fontFamily='Sarabun' fontSize={12} color={statusIcon ? "grayishBlue" : "Text3"}>
              {statusIcon && (
                <img
                  style={{ width: "18px", height: "18px", marginRight: 4 }}
                  src={statusIcon}
                  alt={item.status}
                />
              )}

              {formattedDateRange}
            </Text>
          </Col>
        </Row>
      ),
    };
  });
  const onPopupScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      setTimeout(async () => {
        await handleCampaigns(false);
      }, 300);
    }
  };

  const handleCampaignSelect = (value) => {
    setSearchCampaign(value?.data);
    localStorage.setItem("searchCampOrder", value?.data.campaignId);
    localStorage.setItem("searchCampName", value?.data.campaignName);
    const timeDate = `${moment(value.data?.startDate).format("DD/MM/YYYY")}-${moment(
      value.data?.endDate,
    ).format("DD/MM/YYYY")}`;
    localStorage.setItem("searchCampTimeDate", timeDate);
    setStartEndTime(timeDate);
  };
  const SectionSearch = () => {
    return (
      <Row justify='start' style={{ marginTop: 32 }}>
        <Col className='gutter-row' span={12}>
          <Col span={12}>
            <Input
              placeholder='รหัส / ชื่อร้านค้า'
              suffix={<SearchOutlined />}
              defaultValue={searchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearchText(value);
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchText(undefined);
                }
              }}
            />
          </Col>
        </Col>
        <Col span={12}>
          <Row justify='end' gutter={{ xs: 8, sm: 16 }}>
            <Col className='gutter-row' span={8}>
              <Select
                value={searchZone}
                allowClear
                placeholder='เขตทั้งหมด'
                data={
                  zone.map((z) => ({
                    label: z.zoneName,
                    key: z.zoneId,
                    value: z.zoneName,
                  })) || []
                }
                style={{
                  width: "100%",
                  fontFamily: "Sarabun",
                }}
                onChange={(e) => {
                  setSearchZone(e);
                  setPage(1);
                }}
              />
            </Col>
            <Col className='gutter-row' span={10}>
              <SelectCheckbox
                selectedOptions={selectDanger}
                setSelectedOptions={setSelectDanger}
                placeholder='ระดับแจ้งเตือนทั้งหมด'
                listOption={MAP_DANGER_LEVEL}
                maxTagCount={2}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const tabsItems: any = [
    {
      label: (
        <>
          <WarningFilled />
          <Text fontSize={14} fontWeight={700}>
            ร้านที่ผิดปกติ ({numberFormatter(data.countAbNormalShopFilter, 0)})
          </Text>
        </>
      ),
      key: "true",
    },
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          ร้านค้าทั้งหมด ({numberFormatter(data.countAllShopFilter, 0)})
        </Text>
      ),
      key: "false",
    },
  ];

  const handleModal = (id: string) => {
    setOrderCheckId(id);
    setShowModal(true);
  };

  const sumAbNormal = data.checkLooking + data.checkPreAbNormal + data.checkAbNormal;
  const summaryBoxList = [
    {
      color: "#F3F6F9",
      icon: icon.store,
      title: "ร้านที่ผิดปกติ",
      value: `${numberFormatter(sumAbNormal, 0)}/${numberFormatter(data.checkCountShop, 0)}`,
    },
    {
      color: "#FFF3CC",
      icon: icon.dangerYellow,
      title: "จับตามอง",
      value: numberFormatter(data.checkLooking, 0),
    },
    {
      color: "#FFE8D3",
      icon: icon.dangerOrange,
      title: "เริ่มผิดปกติ",
      value: numberFormatter(data.checkPreAbNormal, 0),
    },
    {
      color: "#FFD9D9",
      icon: icon.dangerRed,
      title: "ผิดปกติ",
      value: numberFormatter(data.checkAbNormal, 0),
    },
  ];

  const columns: any[] = [
    {
      title: "ชื่อร้าน Dealer",
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <DescText style={{ color: color.placeholder }}>
                {row.dealerCode} {row.dealerCode || row.zone ? "• Dealer •" : "-"} {row.zone}
              </DescText>
            </>
          ),
        };
      },
    },
    {
      title: "ระดับแจ้งเตือน",
      dataIndex: "warningLevel",
      key: "warningLevel",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {value === "NORMAL" ? (
                <BaseText>-</BaseText>
              ) : (
                <>
                  <DetailStatusDanger title='รายละเอียดสถานะแจ้งเตือน' data={row}>
                    <StatusBadge
                      label={STATUS_DANGER_WORD[value]}
                      bgColor={STATUS_DANGER_COLOR[value]}
                      icon={STATUS_DANGER_ICON[value]}
                      textStyle={{
                        color: DANGER_WORD_COLOR[value],
                      }}
                    />
                    <DescText>{moment(row.updatedAt).format("DD/MM/YYYY, HH:mm")}</DescText>
                  </DetailStatusDanger>
                </>
              )}
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ยอดสั่งซื้อ Dealer'
          sortDirection={sortField === "dealerOrderSum" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("dealerOrderSum")}
        />
      ),
      dataIndex: "dealerOrderSum",
      key: "dealerOrderSum",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Row>
                <BaseText>{numberFormatter(value, 2)}</BaseText>
                <TotalOrder
                  title='ยอดสั่งซื้อ Dealer'
                  data={row?.dealerOrder}
                  orderNav={row.dealerOrderNav}
                >
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "#464E5F", marginLeft: 6 }}
                  />
                </TotalOrder>
              </Row>

              <DescText>ตัน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ยอดสั่งซื้อ Sub-Dealer'
          sortDirection={sortField === "subDealerOrder" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("subDealerOrder")}
        />
      ),
      dataIndex: "subDealerOrder",
      key: "subDealerOrder",
      render: (value: any, row: any) => {
        return {
          children: (
            <>
              <BaseText style={{ fontWeight: 700 }}>{numberFormatter(value, 2)}</BaseText>
              <DescText>ตัน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวน Sub-Dealer",
      dataIndex: "countSubdealer",
      key: "countSubdealer",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Row>
                <BaseText>{numberFormatter(value, 0)}</BaseText>
                <InfoCircleFilled
                  style={{ fontSize: "16px", color: "#464E5F", marginLeft: 6 }}
                  onClick={() => handleModal(row.orderCheckId)}
                />
              </Row>

              <DescText>ร้าน</DescText>
            </>
          ),
        };
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div className='btn btn-icon btn-light btn-hover-primary btn-sm'>
                <UnorderedListOutlined
                  style={{ color: color["primary"] }}
                  onClick={() => navigate("./" + row.orderCheckId)}
                />
              </div>
            </>
          ),
        };
      },
    },
  ];
  return (
    <OuterCardContainer>
      <CardContainer cutBottom>
        <Row align='middle' gutter={18}>
          <Col className='gutter-row' span={16}>
            <Text fontSize={24} fontWeight={700} color='Text2'>
              รายการตรวจจับยอดสั่งซื้อ
            </Text>
          </Col>
          <Col className='gutter-row' span={8}>
            <CustomAutoComplete
              placeholder='ค้นหารหัส / ชื่อแคมเปญ'
              data={optionsData}
              value={checkCampOrderName || searchCampaign?.campaignName}
              setValue={setSearchCampaign}
              onCampaignSelect={(value) => {
                handleCampaignSelect(value);
              }}
              onPopupScroll={onPopupScroll}
            />
            <div style={{ marginTop: "8px" }}>
              <Text fontFamily='Sarabun' fontSize={12} fontWeight={400} color='Text3'>
                ระยะเวลาแคมเปญ {checkCampOrderTimeDate ? checkCampOrderTimeDate : startEndTime}
              </Text>
            </div>
          </Col>
        </Row>
      </CardContainer>
      <Divider />
      <div style={{ padding: "0px 28px 0px 28px " }}>
        <Row gutter={14}>
          {summaryBoxList.map((e, i) => (
            <CardSummary {...e} key={i} />
          ))}
        </Row>
        <SectionSearch />
      </div>

      <CardContainer cutTop>
        <div style={{ marginTop: 22 }}>
          <Tabs
            items={tabsItems}
            onChange={(key: any) => {
              setIsAbnormal(key);
            }}
          />
          <TablePagination
            locale={{
              emptyText: (
                <div
                  style={{
                    padding: 32,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 24,
                    minHeight: 350,
                  }}
                >
                  <img
                    src={icon.emptyHistoryPoint}
                    style={{
                      width: 80,
                      height: 90,
                    }}
                    alt='empty_table'
                  />
                  <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                    ไม่มีรายชื่อร้านค้า
                  </Text>
                </div>
              ),
            }}
            columns={columns}
            data={data.data}
            scroll={{ x: "max-content" }}
            pagination={{
              current: 1,
              pageSize: 10,
              total: isAbnormal ? data.countAbNormalShopFilter : data.countAllShopFilter,
              onChange: (page) => {
                setPage(page);
              },
            }}
            isQuickJumper
            onPageChange={(pageNum) => setPage(pageNum)}
          />
        </div>
      </CardContainer>
      <ModalPartnerList open={showModal} onCancel={() => setShowModal(false)} id={orderCheckId} />
    </OuterCardContainer>
  );
}

export default DetectionList;

import { Col, Modal, Row, Spin, Table } from "antd";
import styled from "styled-components";
import color from "../../resource/color";
import { useEffect, useState } from "react";
import Text from "../Text/Text";
import { icon } from "../../resource";
import {
  AllStockShopProductEntities,
  AllStockShopProductEntities_INIT,
  StockShopDetailEntities,
  StockShopDetailEntities_INIT,
} from "../../entities/CampaignStockEntity";
import { getStockShopDetail, getStockShopProduct } from "../../datasource/StockDatasource";
import { numberFormatter } from "../../utility/Formatter";

const AntTable = styled(Table)<{ isNotRadius?: boolean }>`
  .ant-table table {
    border-collapse: separete;
  }
  .ant-pagination-item {
    border: 0px;
  }
  .ant-pagination-prev {
    margin-right: 0px;
  }
  .ant-pagination-next {
    margin-left: 0px;
  }
  .ant-pagination-item {
    margin-right: 0px;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: ${({ isNotRadius }) => (isNotRadius ? "0px" : "8px")};
    border-bottom-right-radius: ${({ isNotRadius }) => (isNotRadius ? "0px" : "8px")};
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: ${({ isNotRadius }) => (isNotRadius ? "0px" : "8px")};
    border-bottom-left-radius: ${({ isNotRadius }) => (isNotRadius ? "0px" : "8px")};
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    display: none;
  }
  .ant-table-cell {
    font-family: "IBM Plex Sans Thai", sans-serif;
    font-weight: 700 !important;
    font-size: 16px;
    color: ${color.Text1};
  }
  && tbody > tr:hover > td {
    border-radius: 4px;
  }

  .ant-table-thead > tr > th {
    background-color: ${color.background1};
    border-bottom: 0px;

    padding: 12px 16px;
  }
  .ant-table-expanded-row-fixed {
    font-weight: 400 !important;
  }
`;
interface Props {
  visible: boolean;
  onCancel: () => void;
  dealerId: any;
  stockId?: string;
}
const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const BaseText = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
`;
const Header = styled.div`
  font-family: "IBM Plex Sans Thai";
  font-size: 14px;
  color: ${color.Text1};
  font-weight: 700;
`;
function ModalProductStock({ visible, onCancel, dealerId, stockId }: Props) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [detail, setDetail] = useState<StockShopDetailEntities>(StockShopDetailEntities_INIT);
  const [product, setProduct] = useState<AllStockShopProductEntities>(
    AllStockShopProductEntities_INIT,
  );

  useEffect(() => {
    if (visible) {
      StockShopDetail();
    }
  }, [visible]);

  useEffect(() => {
    if (detail && detail.stockShopId) {
      StockShopProduct();
    }
  }, [detail]);

  const StockShopDetail = async () => {
    setLoading(true);
    try {
      await getStockShopDetail(dealerId.dealerId, stockId).then((res) => {
        setDetail(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const StockShopProduct = async () => {
    setLoading(true);
    try {
      await getStockShopProduct(dealerId.dealerId, detail.stockShopId, 1, 10).then((res) => {
        setProduct(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableScroll = async (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const bottom = target.scrollHeight > target.scrollTop + target.clientHeight;
    if (bottom && product.data.length < product.count && !loading) {
      setLoading(true);
      if (product.data.length < product.count) {
        const res = await getStockShopProduct(dealerId.dealerId, detail.stockShopId, page + 1, 10);
        setProduct({
          ...product,
          data: [...product.data, ...res.data],
        });
        setPage(page + 1);
      }
      setLoading(false);
    }
  };
  const columns: any = [
    {
      title: "ชื่อสินค้า",
      dataIndex: "productName",
      key: "productName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={row.productImage || icon.emptyProduct}
                  style={{ width: "50px", height: "50px", objectFit: "cover", marginRight: "10px" }}
                />
                <div>
                  <BaseText>{value}</BaseText>
                  <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                    {row.commonName}
                  </Text>
                </div>
              </div>
            </>
          ),
        };
      },
    },
    {
      title: "ขนาด",
      dataIndex: "saleUOM",
      key: "saleUOM",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText>{value}</BaseText>
              <BaseText style={{ color: color.Disable }}>{row.productCodeNav}</BaseText>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวนสต๊อก",
      dataIndex: "quantity",
      key: "quantity",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <BaseText style={{ color: value ? color.Text1 : color.Disable }}>
                {value === null ? "ยังไม่ได้ระบุ" : numberFormatter(value, 2)}
              </BaseText>
              <BaseText style={{ color: color.Disable }}>ตัน</BaseText>
            </>
          ),
        };
      },
    },
  ];

  return (
    <ModalStyled
      open={visible}
      onCancel={() => {
        setPage(1);
        onCancel();
      }}
      maskClosable={false}
      width={900}
      footer={false}
      centered
    >
      <div
        style={{
          display: "flex",
          padding: "24px 24px 10px 24px",
        }}
      >
        <Text fontSize={20} fontWeight={700}>
          รายการสินค้าและสต๊อกในร้าน
        </Text>
      </div>
      <div style={{ backgroundColor: color.background1 }}>
        <div style={{ padding: "24px" }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header>รหัสร้านค้า:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header> ชื่อร้านค้า:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header> เขต:</Header>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>{detail?.dealerCode}</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>{detail.dealerName}</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>{detail.zone}</Header>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: "14px" }}>
            <Col className='gutter-row' span={8}>
              <Header>จำนวนสินค้าที่ขาย:</Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header>ยอดรวมสต๊อก:</Header>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>
                {numberFormatter(detail.countProduct, 0)} รายการ
              </Header>
            </Col>
            <Col className='gutter-row' span={8}>
              <Header style={{ fontWeight: 400 }}>
                {numberFormatter(detail.sumQuantity, 2) || "-"} ตัน
              </Header>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ padding: "24px" }}>
        <AntTable
          onScroll={handleTableScroll}
          scroll={{ y: 350 }}
          columns={columns}
          dataSource={product.data}
          pagination={false}
          locale={{
            emptyText: (
              <div
                style={{
                  padding: 32,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 24,
                  minHeight: 350,
                }}
              >
                <img
                  src={icon.emptyHistoryPoint}
                  style={{
                    width: 80,
                    height: 90,
                  }}
                  alt='empty_table'
                />
                <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                  ไม่มีรายการสต๊อกร้านค้า
                </Text>
              </div>
            ),
          }}
        />
      </div>
    </ModalStyled>
  );
}

export default ModalProductStock;

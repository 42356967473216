import React, { useEffect, useState } from "react";
import { reportCampaignShop } from "../../../../datasource/CampaignDatasource";
import { useNavigate } from "react-router-dom";
import { DealerType } from "../../../../definitions/campaign";
import { Col, Row, Table } from "antd";
import Descriptions from "../../../../components/Description/Descriptions";
import Text from "../../../../components/Text/Text";
import moment from "moment";
import { FlexCol } from "../../../../components/Container/Container";
import { numberFormatter } from "../../../../utility/Formatter";
import { DownOutlined, StarFilled, UpOutlined } from "@ant-design/icons";
import GreyTable from "../../../../components/Table/GreyTable";
import image from "../../../../resource/image";
import icon from "../../../../resource/icon";

const TabCurrentCampaign: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [count, setCount] = useState<number>(0);
  const [summary, setSummary] = useState<any>();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (!loading) fetchData();
  }, [page]);

  const fetchData = async () => {
    setLoading(true);
    const type = pathSplit[3];
    const id = pathSplit[4];
    await reportCampaignShop({
      dealerId: type?.toUpperCase() === DealerType.DEALER ? parseInt(id) : undefined,
      subDealerId: type?.toUpperCase() === DealerType.DEALER ? undefined : parseInt(id),
      page,
      take: 10,
    })
      .then((res) => {
        console.log("tab1", res);
        setData(res?.data);
        setCount(res?.count);
        setSummary(res?.summary);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columnWidth = [158, 0, 0, 320, 150, 150, 150];
  const columns = [
    {
      title: "รหัสแคมเปญ",
      dataIndex: "campaignCode",
      key: "campaignCode",
      width: columnWidth[0],
    },
    {
      title: "ชื่อแคมเปญ",
      dataIndex: "campaignName",
      key: "campaignName",
    },
    {
      title: "ระยะเวลา",
      dataIndex: "startDate",
      key: "startDate",
      render: (value, row) => {
        return (
          <Text>{`${row.startDate ? moment(row.startDate).format("DD/MM/YYYY") : ""} - ${
            row.endDate ? moment(row.endDate).format("DD/MM/YYYY") : ""
          }`}</Text>
        );
      },
    },
    {
      title: "จำนวนคำสั่งซื้อ",
      dataIndex: "countOrder",
      key: "countOrder",
      width: columnWidth[3],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              รายการ
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumCampaignQuantity",
      key: "sumCampaignQuantity",
      width: columnWidth[4],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              ตัน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumCampaignPoint",
      key: "sumCampaignPoint",
      width: columnWidth[5],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const expandColumns = [
    {
      title: "",
      dataIndex: "",
      key: "campaignProgramId",
      width: columnWidth[0] - 48,
    },
    {
      title: "",
      dataIndex: "campaignProgramName",
      key: "campaignProgramName",
    },
    {
      title: "จำนวนคำสั่งซื้อ",
      dataIndex: "countOrder",
      key: "countOrder",
      width: columnWidth[3],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{value}</Text>
            <Text level={6} color='Text3'>
              รายการ
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "ปริมาณที่สั่งซื้อ",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      width: columnWidth[4],
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              ตัน
            </Text>
          </FlexCol>
        );
      },
    },
    {
      title: "แต้มที่ได้รับ",
      dataIndex: "sumPoint",
      key: "sumPoint",
      width: columnWidth[5] + 48,
      render: (value) => {
        return (
          <FlexCol>
            <Text>{numberFormatter(value, 2)}</Text>
            <Text level={6} color='Text3'>
              แต้ม
            </Text>
          </FlexCol>
        );
      },
    },
  ];

  const expandedRowRender = (expandDataSource) => (
    <GreyTable
      columns={expandColumns}
      dataSource={expandDataSource}
      pagination={false}
      showHeader={false}
    />
  );
  return (
    <>
      <Row>
        <Col span={14}>
          <Descriptions
            label={
              <>
                <StarFilled style={{ color: "#F4BF00", fontSize: 16 }} />
                &nbsp;แต้มสะสม
              </>
            }
            value={(summary?.sumPoint || "0") + " แต้ม"}
            noColon
          />
        </Col>
      </Row>
      <Row align='bottom'>
        <Col span={14}>
          <Descriptions
            label={
              <>
                <img src={icon.iconProductBox} style={{ width: 18, height: 18 }} />
                &nbsp;ตันสะสม
              </>
            }
            value={(summary?.sumQuantity || "0") + " ตัน"}
            noColon
          />
        </Col>
        <Col span={10}>
          <Row justify='end'>
            <Text>ทั้งหมด&nbsp;{count || 0}&nbsp;รายการ</Text>
          </Row>
        </Col>
      </Row>
      <br />
      <Table
        className='rounded-lg'
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={data?.map((d, i) => ({ ...d, key: i })) || []}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          current: page,
          total: data?.length || 0,
          onChange: (p) => setPage(p),
          showSizeChanger: false,
        }}
        size='large'
        tableLayout='fixed'
        loading={loading}
        expandable={{
          fixed: "right",
          expandedRowRender: (r) => expandedRowRender(r?.campaignProgram),
          rowExpandable: (r) => r?.campaignProgram?.length,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </>
  );
};

export default TabCurrentCampaign;

import { Col, Row } from "antd";
import { CSSProperties, ReactNode } from "react";
import Text from "../Text/Text";

interface DescriptionProps {
  label: any;
  value?: string | ReactNode;
  leftSpan?: number;
  style?: CSSProperties;
  noColon?: boolean;
  noPadding?: boolean;
}

const Descriptions = ({
  label,
  value,
  leftSpan = 8,
  style,
  noColon,
  noPadding,
}: DescriptionProps) => {
  return (
    <Row style={{ padding: noPadding ? 0 : "16px 0px", ...style }}>
      <Col span={leftSpan}>
        <Text color='Text3'>
          {label}&nbsp;{noColon ? "" : ":"}
        </Text>
      </Col>
      <Col span={24 - leftSpan}>
        <Text>{value || "-"}</Text>
      </Col>
    </Row>
  );
};

export default Descriptions;

import { Col } from "antd";
import React from "react";
import { FlexRow } from "../Container/Container";
import Text from "../Text/Text";

export const CardSummary = ({
  title,
  value,
  icon,
  color,
}: {
  title: string;
  value: number | string;
  icon: string;
  color: string;
}) => {
  return (
    <Col span={6}>
      <div
        style={{
          width: "100%",
          padding: "20px 24px",
          borderRadius: 20,
          backgroundColor: color,
          border: "1px solid #E5EAEE",
        }}
      >
        <FlexRow align='center' justify='space-between'>
          <Text level={1} color='Text1' fontSize={32} fontWeight={700}>
            {value}
          </Text>
          <img src={icon} style={{ width: 32, height: 32 }} />
        </FlexRow>
        <div>
          <Text level={4} color='Text1' fontWeight={700}>
            {title}
          </Text>
        </div>
      </div>
    </Col>
  );
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Divider, Row } from "antd";
import Text from "../../../../components/Text/Text";
import color from "../../../../resource/color";
import { icon } from "../../../../resource";
import { numberFormatter } from "../../../../utility/Formatter";
import { CustomTableWrapper } from "./HistoryTab";
import Input from "../../../../components/Input/Input";
import { InfoCircleFilled, SearchOutlined } from "@ant-design/icons";
import Select from "../../../../components/Select/Select";
import { zoneDatasource } from "../../../../datasource/ZoneDatasource";
import Button from "../../../../components/Button/Button";
import UploadCSVButton from "../../../../components/Button/UploadCSVButton";
import TablePagination from "../../../../components/Table/TablePagination";
import { InfoIconWrapper } from "../../../ReportCampaignPage/TableList/CampaignList";
import ModalProductStock from "../../../../components/Modal/ModalProductStock";
import ModalError from "../../../../components/Modal/ModalError";
import {
  AllStockDealerEntities,
  AllStockDealerEntities_INIT,
  StockDealerEntities,
  StockDealerEntities_INIT,
  StockDetailEntities,
} from "../../../../entities/CampaignStockEntity";
import moment from "moment";
import { getCampaignStockDealer } from "../../../../datasource/StockDatasource";
import SortFieldCustom from "../../../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../../../hook/useSorting";
import { el } from "date-fns/locale";

const Texts = styled.div`
  font-family: "Sarabun";
  font-size: 16px;
  color: ${color.Text1};
  font-weight: 400;
  word-break: break-word;
  max-width: 100%;
  white-space: normal;
`;

interface Props {
  data: StockDetailEntities;
  newFetch: () => void;
}

function DetailTab({ data, newFetch }: Props) {
  const campaignId = window.location.pathname.split("/")[3];
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [searchZone, setSearchZone] = useState<string | undefined>(undefined);
  const [zone, setZone] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stockList, setStockList] = useState<AllStockDealerEntities>(AllStockDealerEntities_INIT);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [clearSearchText, setClearSearchText] = useState<string | undefined>(undefined);
  const [tooltip, setTooltipData] = useState<StockDealerEntities>(StockDealerEntities_INIT);

  useEffect(() => {
    const getZone = async () => {
      await zoneDatasource.getAllZoneByCompany(userProfile?.company).then((res) => {
        setZone(res);
      });
    };
    getZone();
  }, []);

  useEffect(() => {
    getStockDealer();
  }, [searchText, searchZone, sortField, sortDirection, page]);

  const getStockDealer = async () => {
    setLoading(true);
    try {
      await getCampaignStockDealer(
        campaignId,
        page,
        10,
        searchText,
        searchZone,
        sortField,
        sortDirection,
      ).then((res) => {
        setStockList(res);
      });
    } catch (error) {
      console.log("err campaign stock dealer", error);
    } finally {
      setLoading(false);
    }
  };

  const downLoadFile = async (fileUrl) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const columns: any[] = [
    {
      title: () => (
        <SortFieldCustom
          title='รหัสร้านค้า'
          sortDirection={sortField === "dealerNo" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("dealerNo")}
        />
      ),
      dataIndex: "dealerNo",
      key: "dealerNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Texts>{value}</Texts>,
        };
      },
    },
    {
      title: () => (
        <SortFieldCustom
          title='ชื่อร้านค้า'
          sortDirection={sortField === "dealerName" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("dealerName")}
        />
      ),
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Texts>{value}</Texts>
            </>
          ),
        };
      },
    },
    {
      title: "เขตการขาย",
      dataIndex: "zone",
      key: "zone",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Texts>{value}</Texts>
            </>
          ),
        };
      },
    },
    {
      title: "ยอดรวมสต๊อก",
      dataIndex: "sumQuantity",
      key: "sumQuantity",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Texts style={{ color: value ? color.Text1 : color.grayishBlue }}>
                {value ? numberFormatter(value, 2) : "ยังไม่ได้ระบุ"}
              </Texts>
              <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                ตัน
              </Text>
            </>
          ),
        };
      },
    },
    {
      title: "จำนวนสินค้า",
      dataIndex: "countProduct",
      key: "countProduct",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              <Row>
                <Texts>{numberFormatter(value, 0)}</Texts>
                <InfoIconWrapper>
                  <InfoCircleFilled
                    style={{ fontSize: "13px" }}
                    onClick={() => {
                      setShowModal(true);
                      setTooltipData(row);
                    }}
                  />
                </InfoIconWrapper>
              </Row>

              <Text fontFamily='Sarabun' fontSize={14} color='Text3'>
                รายการ
              </Text>
            </>
          ),
        };
      },
    },
  ];

  return (
    <CustomTableWrapper>
      <div>
        <Row style={{ margin: "20px 0px 10px 0px" }}>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Text fontSize={16} fontWeight={700}>
              ข้อมูลแคมเปญ
            </Text>
            <Row gutter={[0, 12]} style={{ margin: "20px 0px 10px 0px" }}>
              <Col span={10}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  ชื่อแคมเปญ :
                </Text>
              </Col>
              <Col span={14} style={{ display: "flex", alignItems: "center" }}>
                <Texts>{data.campaignName}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={10}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  รหัสแคมเปญ :
                </Text>
              </Col>
              <Col span={14}>
                <Texts>{data.campaignCode}</Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={10}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  ระยะเวลาแคมเปญ :
                </Text>
              </Col>
              <Col span={14}>
                <Texts>
                  {moment(data.startDate).format("DD/MM/YYYY") +
                    " - " +
                    moment(data.endDate).format("DD/MM/YYYY")}
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]}>
              <Col span={10}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  จำนวนร้านค้า Dealer :
                </Text>
              </Col>
              <Col span={14}>
                <Texts>{numberFormatter(data.countDealer, 0)} ร้าน</Texts>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Text fontSize={16} fontWeight={700}>
              ข้อมูลการนำเข้าสต๊อก
            </Text>
            <Row gutter={[0, 12]} style={{ margin: "20px 0px 10px 0px" }}>
              <Col span={12}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  วันที่อัปโหลดล่าสุด :
                </Text>
              </Col>
              <Col span={12}>
                <Texts>
                  {data.stock ? moment(data.stock.updateAt).format("DD/MM/YYYY, HH:mm") : "-"}
                </Texts>
              </Col>
            </Row>
            <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Text color='grayishBlue' fontFamily='Sarabun'>
                  ผู้อัปโหลดล่าสุด :
                </Text>
              </Col>
              <Col span={12}>
                <Texts> {data.stock ? data.stock.updatedBy : "-"}</Texts>
              </Col>
            </Row>
            {data.stock && (
              <Row gutter={[0, 12]} style={{ marginBottom: 10 }}>
                <Col span={12}>
                  <Text color='grayishBlue' fontFamily='Sarabun'>
                    ไฟล์นำเข้าล่าสุด :
                  </Text>
                </Col>
                <Col span={12}>
                  <Row
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={icon.downloadFileIcon}
                      style={{ width: 16, height: 16, marginRight: 4 }}
                    />
                    <Text
                      fontFamily='Sarabun'
                      color='primary'
                      onClick={() => downLoadFile(data.stock.pathFile)}
                    >
                      ดาวน์โหลด
                    </Text>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Divider />
      <div>
        <Text fontSize={16} fontWeight={700}>
          สต๊อกร้านค้า
        </Text>
        <Row gutter={[8, 16]} style={{ margin: "10px 0px 20px 0px" }}>
          <Col className='gutter-row' span={6}>
            <Input
              placeholder='รหัส/ชื่อร้านค้า'
              suffix={<SearchOutlined />}
              defaultValue={searchText || clearSearchText}
              value={clearSearchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearchText(value);
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                setClearSearchText(value);
                if (!value) {
                  setSearchText(undefined);
                }
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Select
              value={searchZone}
              allowClear
              placeholder='เขตทั้งหมด'
              data={
                zone.map((z) => ({
                  label: z.zoneName,
                  key: z.zoneId,
                  value: z.zoneName,
                })) || []
              }
              style={{
                width: "100%",
                fontFamily: "Sarabun",
              }}
              onChange={(e) => {
                setSearchZone(e);
                setPage(1);
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Col className='gutter-row' span={16}>
              <Button
                title='ล้างการค้นหา'
                typeButton='primary-light'
                onClick={() => {
                  setSearchZone(undefined);
                  setSearchText(undefined);
                  setClearSearchText(undefined);
                }}
              />
            </Col>
          </Col>
          <Col className='gutter-row' span={10} style={{ display: "flex", justifyContent: "end" }}>
            <Col className='gutter-row'>
              <UploadCSVButton
                campaignId={data.campaignId}
                title='อัปโหลดไฟล์นำเข้าสต็อก'
                statusImport={stockList.statusImport}
                stockId={data.stock?.stockId}
              />
            </Col>
          </Col>
        </Row>

        <TablePagination
          isRadiusTable={true}
          columns={columns}
          data={stockList.data}
          locale={{
            emptyText: (
              <div
                style={{
                  padding: 32,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 24,
                  minHeight: 350,
                }}
              >
                <img
                  src={icon.emptyHistoryPoint}
                  style={{
                    width: 80,
                    height: 90,
                  }}
                  alt='empty_table'
                />
                <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                  ไม่มีรายการสต๊อกร้านค้า
                </Text>
              </div>
            ),
          }}
          isLoading={loading}
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            total: stockList.count,
            pageSize: 10,
          }}
          isQuickJumper
          onPageChange={(pageNum) => setPage(pageNum)}
        />
      </div>

      <ModalProductStock
        visible={showModal}
        onCancel={() => setShowModal(false)}
        dealerId={tooltip}
        stockId={data.stock?.stockId}
      />
    </CustomTableWrapper>
  );
}

export default DetailTab;

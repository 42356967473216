export interface OrderCheckHistory {
  createdAt: string;
  detail: string;
  user: string;
}

export interface OrderCheckEntities {
  orderCheckId: string;
  dealerId: number;
  dealerName: string;
  dealerCode: string;
  company: string;
  zone: string;
  warningLevel: string;
  dealerOrderSum: number;
  dealerOrder: number;
  dealerOrderNav: number;
  subDealerOrder: number;
  campaignId: string;
  createdAt: string;
  updatedAt: string;
  countSubdealer: number;
  examineGroupName: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  countSubDealer: number;
  percent: string;
  history: OrderCheckHistory;
  abNormal: number;
  looking: number;
  preABNormal: number;
}

export const OrderCheckEntities_INIT: OrderCheckEntities = {
  orderCheckId: "",
  dealerId: 0,
  dealerName: "",
  dealerCode: "",
  company: "",
  zone: "",
  warningLevel: "",
  dealerOrderSum: 0,
  dealerOrder: 0,
  dealerOrderNav: 0,
  subDealerOrder: 0,
  campaignId: "",
  createdAt: "",
  updatedAt: "",
  countSubdealer: 0,
  examineGroupName: "",
  percent: "",
  campaignName: "",
  startDate: "",
  endDate: "",
  countSubDealer: 0,
  history: { createdAt: "", detail: "", user: "" },
  abNormal: 0,
  looking: 0,
  preABNormal: 0,
};
export interface AllOrderCheckEntities {
  checkAbNormal: number;
  checkCountShop: number;
  checkLooking: number;
  checkPreAbNormal: number;
  countAbNormalShopFilter: number;
  countAllShopFilter: number;
  data: OrderCheckEntities[];
}
export const AllOrderCheckEntities_INIT: AllOrderCheckEntities = {
  checkAbNormal: 0,
  checkCountShop: 0,
  checkLooking: 0,
  checkPreAbNormal: 0,
  countAbNormalShopFilter: 0,
  countAllShopFilter: 0,
  data: [OrderCheckEntities_INIT],
};
export interface SubDealerCheckEntities {
  amountOrder: number;
  countOrder: number;
  orderCheckId: string;
  province: string;
  subDealerCheckId: string;
  subDealerCode: string;
  subDealerId: number;
  subDealerName: string;
  zone: string;
}
export const SubDealerCheckEntities_INIT: SubDealerCheckEntities = {
  amountOrder: 0,
  countOrder: 0,
  orderCheckId: "",
  province: "",
  subDealerCheckId: "",
  subDealerCode: "",
  subDealerId: 0,
  subDealerName: "",
  zone: "",
};

export interface ProductCheckEntities {
  productCheckId: string;
  orderCheckId: string;
  productName: string;
  productCommonName: string;
  productId: number;
  saleUom: string;
  productCodeNav: string;
  dealerOrder: string;
  subDealerOrder: string;
  createdAt: string;
  updatedAt: string;
  productImage: string;
}

export interface PostHistoryCheckEntities {
  orderCheckId: string;
  detail: string;
  user: string;
  userPosition: string;
}

export interface HistoryCheckEntities {
  createdAt: string;
  detail: string;
  historyCheckId: string;
  orderCheckId: string;
  user: string;
  userPosition: string;
}

import { Popover } from "antd";
import React from "react";
import Text from "../../Text/Text";
import color from "../../../resource/color";
import styled from "styled-components";
import { OrderContent } from "./OrderContent";

const StyledPopover = styled(Popover)``;
type TotalOrderProps = {
  title: string;
  data: any;
  children: React.ReactNode;
  orderNav: number;
};

function TotalOrder({ title, children, data, orderNav }: TotalOrderProps) {

  const header = () => {
    return (
      <div
        style={{
          backgroundColor: color.grayishBlue,
          padding: "8px 12px",
          borderRadius: "4px 4px 0px 0px",
          margin: "-4% -4% 0% -4%",
        }}
      >
        <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700} color='white'>
          {title}
        </Text>
      </div>
    );
  };

  return (
    <StyledPopover
      overlayStyle={{ width: "330px", padding: 0 }}
      content={<OrderContent data={data} typeColumn='dealer' orderNav={orderNav} />}
      title={header}
    >
      {children}
    </StyledPopover>
  );
}

export default TotalOrder;

import dayjs from "dayjs";
import Text from "../../../../components/Text/Text";
import { CustomTableWrapper } from "../../StockSetting/Tabs/HistoryTab";
import icon from "../../../../resource/icon";
import { Flex } from "./styled";
import React, { useState } from "react";
import HistoryModal from "./HistoryModal";
import TablePagination from "../../../../components/Table/TablePagination";
import { getHistoryCheck } from "../../../../datasource/orderCheckDatasource";
import { useQuery } from "react-query";
import { HistoryCheckEntities } from "../../../../entities/OrderCheckEntity";

interface Props {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  selectedTab: string;
  id: string;
}
const HistoryTab: React.FC<Props> = ({ page, setPage, selectedTab, id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const take = 10;
  const [detailData, setDetailData] = useState<HistoryCheckEntities | undefined>(undefined);

  const { data, isLoading } = useQuery(
    ["historyTab", selectedTab, id, page],
    async () => {
      if (selectedTab === "history" && !!id) {
        return await getHistoryCheck({ page, take, orderCheckId: id });
      }
    },
    { enabled: selectedTab === "history" && !!id, refetchOnWindowFocus: false },
  );

  const columns = [
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: (
        <Text level={6} fontWeight={700} color='white'>
          วันเวลาอัปเดต
        </Text>
      ),
      render: (text) => (
        <Text fontFamily='Sarabun' level={5}>
          {dayjs(text).format("DD/MM/YYYY, HH:mm น.")}
        </Text>
      ),
    },
    {
      key: "action",
      dataIndex: "orderCheckId",
      title: (
        <Text level={6} fontWeight={700} color='white'>
          บันทึกการตรวจสอบ
        </Text>
      ),
      render: (text, record) => (
        <Flex
          gap={6}
          alignItems='center'
          onClick={() => {
            setDetailData(record);
            setOpen(true);
          }}
          className='pointer'
        >
          <Text fontFamily='Sarabun' level={5}>
            ดูบันทึก
          </Text>
          <img src={icon.filePreview} alt='preview' width={16} height={16} />
        </Flex>
      ),
    },
    {
      key: "user",
      dataIndex: "user",
      title: (
        <Text level={6} fontWeight={700} color='white'>
          ผู้ใช้งาน
        </Text>
      ),
      render: (text, record) => (
        <>
          <div>
            <Text fontFamily='Sarabun' level={5}>
              {text}
            </Text>
          </div>
          <div>
            <Text fontFamily='Sarabun' level={6} color='Text3'>
              {record?.userPosition}
            </Text>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomTableWrapper>
        <TablePagination
          isLoading={isLoading}
          locale={{
            emptyText: (
              <div
                style={{
                  padding: 32,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 24,
                  minHeight: 350,
                }}
              >
                <img
                  src={icon.emptyHistoryPoint}
                  style={{
                    width: 80,
                    height: 90,
                  }}
                  alt='empty_table'
                />
                <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='Text3'>
                  ไม่มีประวัติบันทึกการตรวจสอบ
                </Text>
              </div>
            ),
          }}
          columns={columns}
          data={data?.data || []}
          scroll={{ x: "max-content" }}
          pagination={{
            current: page,
            pageSize: take,
            total: data?.count,
            onChange: (p) => {
              setPage(p);
            },
          }}
          isQuickJumper
          onPageChange={(pageNum) => setPage(pageNum)}
        />
      </CustomTableWrapper>
      <HistoryModal
        open={open}
        setOpen={setOpen}
        detailData={detailData}
        setDetailData={setDetailData}
      />
    </>
  );
};
export default HistoryTab;

import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import Text from "../Text/Text";
import TablePagination from "../Table/TablePagination";
import { getSubDealerCheck } from "../../datasource/orderCheckDatasource";
import { numberFormatter } from "../../utility/Formatter";
import {
  SubDealerCheckEntities_INIT,
  type SubDealerCheckEntities,
} from "../../entities/OrderCheckEntity";

interface Props {
  open: boolean;
  onCancel: () => void;
  id: any;
}

function ModalPartnerList({ open, onCancel, id }: Props) {
  const [data, setData] = useState<SubDealerCheckEntities[]>([SubDealerCheckEntities_INIT]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchSubDealer();
    }
  }, [open]);

  const fetchSubDealer = async () => {
    try {
      await getSubDealerCheck(0, 0, id).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const columns: any[] = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <Text>{index !== undefined ? index + 1 : "-"}</Text>,
    },
    {
      title: "รหัสร้านค้า",
      dataIndex: "subDealerCode",
      key: "subDealerCode",
      render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "subDealerName",
      key: "subDealerName",
      render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
    },
    {
      title: "เขต",
      dataIndex: "zone",
      key: "zone",
      render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
      render: (value) => <Text fontFamily='Sarabun'>{value || "-"}</Text>,
    },
    {
      title: "คำสั่งซื้อ",
      dataIndex: "countOrder",
      key: "countOrder",
      sorter: (a, b) => a.countOrder - b.countOrder,
      render: (value) => <Text fontFamily='Sarabun'>{numberFormatter(value, 0) || "-"}</Text>,
    },
    {
      title: "ปริมาณที่สั่งซื้อ (ตัน)",
      dataIndex: "amountOrder",
      key: "amountOrder",
      sorter: (a, b) => a.amountOrder - b.amountOrder,
      render: (value) => <Text fontFamily='Sarabun'>{numberFormatter(value, 2) || "-"}</Text>,
    },
  ];

  return (
    <Modal
      width='max-content'
      open={open}
      closable
      onCancel={onCancel}
      title={
        <>
          <Text fontWeight={600} fontSize={20}>
            รายชื่อคู่ค้ากับ Sub-Dealer
          </Text>
          <br />
          <Text fontWeight={400} fontSize={14} color='Text3' fontFamily='Sarabun'>
            ทั้งหมด {data?.length} ร้านค้า
          </Text>
        </>
      }
      centered
      footer={null}
    >
      <TablePagination
        isLoading={loading}
        data={data}
        scroll={{ x: "max-content" }}
        columns={columns}
      />
    </Modal>
  );
}

export default ModalPartnerList;

import React, { useState, useEffect } from "react";
import { Input, Pagination } from "antd";
import styled from "styled-components";
import Text from "../Text/Text";

const QuickJumperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
`;

const PaginationWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const JumpInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const JumpInput = styled(Input)`
  width: 60px;
  font-size: 16px;
`;

interface QuickJumperProps {
  currentPage: number;
  totalPages: number;
  onJump: (page: number) => void;
  onPageChange: (page: number) => void;
  isQuickJumper: boolean;
}

const QuickJumper: React.FC<QuickJumperProps> = ({
  currentPage,
  totalPages,
  onJump,
  onPageChange,
  isQuickJumper = false,
}) => {
  const [page, setPage] = useState<string>(String(currentPage));
  const [isInputChanged, setIsInputChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!isInputChanged) {
      setPage(String(currentPage));
    }
  }, [currentPage, isInputChanged]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPage(value);
      setIsInputChanged(true);
    }
  };

  const handleJump = () => {
    const pageNum = parseInt(page, 10);
    if (pageNum >= 1 && pageNum <= totalPages) {
      onJump(pageNum);
      onPageChange(pageNum);
      setIsInputChanged(false);
    } else {
      setPage(String(currentPage));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleJump();
    }
  };

  return (
    <QuickJumperContainer>
      <PaginationWrapper>
        <Pagination
          style={{
            fontSize: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          current={currentPage}
          total={totalPages}
          onChange={(page: number) => {
            onPageChange(page);
            setIsInputChanged(false);
          }}
          showSizeChanger={false}
          hideOnSinglePage
        />
      </PaginationWrapper>
      {isQuickJumper && (
        <JumpInputWrapper>
          <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
            ไปหน้า :
          </Text>
          <JumpInput
            value={isInputChanged ? page : ""}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            max={totalPages}
          />
        </JumpInputWrapper>
      )}
    </QuickJumperContainer>
  );
};

export default QuickJumper;

export default {
  primary: "#0068F4",
  secondary: "#1B4586",
  secondary2: "#1B9BF7",
  background1: "#F3F6F9",
  background2: "#E5EAEE",
  background4: "#BABCBE",
  BK: "#2B2B2B",
  Grey: "#7B7B7B",
  Disable: "#C6C6C6",
  grayishBlue: "#8A93A8",
  Text1: "#464E5F",
  Text2: "#1B283F",
  Text3: "#BABCBE",
  white: "#FFFFFF",
  success: "#2ED477",
  darkGreen: "#3AAE49",
  warning: "#F2994A",
  error: "#EB5757",
  border: "#E8ECF3",
  placeholder: "#BABCBE",
  purple: "#9B51E0",
  black: "#1B283F",
  gold: "#B88F00",
  lightGold: "#F4BF0029",
  yellow: "#F4BF00",
  lightSuccess: "#DFF2E2",
  lightWarning: "#FFF5F5",
  emergency: "#FFD6D6",
  critical: "#FFE8D3",
  warning1: "#FFF3CC",
  darkGold: "#665501",
  darkRed: "#660000",
  chocolateBrown: "#663800",
  disabledArea: "#FAFAFA",
};

import { Col, Divider, Popover, Row } from "antd";
import React from "react";
import styled from "styled-components";
import Text from "../../Text/Text";
import color from "../../../resource/color";
import { OrderContent } from "./OrderContent";
import { icon } from "../../../resource";
import type { OrderCheckEntities } from "../../../entities/OrderCheckEntity";
import { numberFormatter } from "../../../utility/Formatter";

const StyledPopover = styled(Popover)`
  .ant-popover-inner {
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
  }
`;

const Header = styled.div`
  background-color: ${color.grayishBlue};
  padding: 8px 12px;
  border-radius: 4px 4px 0 0;
  margin: -3.2% -3.2% 0% -3.2%;
`;

const RowContent = styled(Row)`
  margin-bottom: 14px;
`;

const IconRow = styled(Row)`
  margin-bottom: 10px;
  align-items: center;

  img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }
`;

type Props = {
  title: string;
  data: OrderCheckEntities;
  children: React.ReactNode;
};

const TextRow = ({ label, value, bold }: { label: string; value: string; bold?: boolean }) => (
  <RowContent>
    <Col span={18} style={{ display: "flex", alignItems: "center" }}>
      <Text fontFamily='Sarabun' fontSize={14} fontWeight={bold ? 700 : 400}>
        {label}
      </Text>
    </Col>
    <Col span={6} style={{ textAlign: "right" }}>
      <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
        {value}
      </Text>
    </Col>
  </RowContent>
);

function DetailStatusDanger({ title, children, data }: Props) {
  const header = (
    <Header>
      <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700} color='white'>
        {title}
      </Text>
    </Header>
  );

  const content = (
    <div>
      <TextRow label='ชื่อกลุ่มตรวจจับ' value={data?.examineGroupName} bold />
      <TextRow
        label='% การตรวจจับยอดสั่งซื้อผิดปกติ Sub-Dealer เมื่อเทียบกับยอดสั่งซื้อ Dealer'
        value={`${data?.percent} %`}
      />
      <Divider />
      <TextRow
        label='ยอดสั่งซื้อร้าน Dealer'
        value={`${numberFormatter(data?.dealerOrderSum)} ตัน`}
      />
      <div style={{ marginBottom: 14 }}>
        <OrderContent
          data={data?.dealerOrder}
          typeColumn='notification'
          orderNav={data?.dealerOrderNav}
        />
      </div>
      <Text fontFamily='Sarabun' fontSize={14} fontWeight={700}>
        ระดับการตรวจจับ
      </Text>
      <IconRow>
        <Col span={16}>
          <img src={icon.dangerYellow} alt='warning' />
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            จับตามอง
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            {numberFormatter(data?.looking)} ตัน
          </Text>
        </Col>
      </IconRow>
      <IconRow>
        <Col span={16}>
          <img src={icon.dangerOrange} alt='warning' />
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            เริ่มผิดปกติ
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            {numberFormatter(data?.preABNormal)} ตัน
          </Text>
        </Col>
      </IconRow>
      <IconRow>
        <Col span={16}>
          <img src={icon.dangerRed} alt='warning' />
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            ผิดปกติ
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400}>
            {numberFormatter(data?.abNormal)} ตัน
          </Text>
        </Col>
      </IconRow>
    </div>
  );

  return (
    <StyledPopover arrow={false} overlayStyle={{ width: "407px" }} content={content} title={header}>
      {children}
    </StyledPopover>
  );
}

export default DetailStatusDanger;
